import { render, staticRenderFns } from "./pay.vue?vue&type=template&id=c7753212&scoped=true&"
import script from "./pay.vue?vue&type=script&lang=js&"
export * from "./pay.vue?vue&type=script&lang=js&"
import style0 from "./pay.vue?vue&type=style&index=0&id=c7753212&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7753212",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsRewardCoin: require('/runner/_work/checkout/checkout/components/Icons/RewardCoin.vue').default,IconsAngleRight: require('/runner/_work/checkout/checkout/components/Icons/AngleRight.vue').default,IconsAngleUp: require('/runner/_work/checkout/checkout/components/Icons/AngleUp.vue').default,IconsInfo: require('/runner/_work/checkout/checkout/components/Icons/Info.vue').default})
