import axios from 'axios'

const initState = () => {
  return {
    rewards: [],
    selectedRewards: [],
    trashRewards: [],
    totalSelected: 0,
    rewardLoading: false,
    rewardEstimation: 0,
  }
}
// initial state
export const state = () => initState()

// mutations defined as object
export const mutations = {
  reset(state) {
    Object.assign(state, initState())
  },
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
  toggleReward(state, reward) {
    const index = state.selectedRewards.findIndex(
      (rw) => rw.reward_id === reward.reward_id
    )
    console.log('toggleReward index:', index)

    if (index === -1) {
      state.selectedRewards.push(reward)
    } else {
      state.selectedRewards.splice(index, 1)
    }
  },
  addReward(state, rwd) {
    const inSelectedRewards = state.selectedRewards.some(
      (x) => x.reward_id === rwd.reward_id
    )
    if (!inSelectedRewards) state.selectedRewards.push(rwd)
  },
  removeReward(state, rwd) {
    state.selectedRewards = state.selectedRewards.filter(
      (x) => x.reward_id !== rwd.reward_id
    )
  },
  calcTotalSelected(state) {
    let total = 0
    for (const rwd of state.selectedRewards) {
      total += rwd.applicable_amount
    }
    state.totalSelected = total
  },
  setRewardEstimation(state, par) {
    state.rewardEstimation = par
  },
  addTrashReward(state, rwd) {
    state.trashRewards.push(rwd)
  },
  removeTrashReward(state, rwd) {
    state.trashRewards = state.trashRewards.filter(
      (x) => x.reward_id !== rwd.reward_id
    )
  },
  setRewards(state, rewards) {
    state.rewards = rewards
  },
}

// actions
export const actions = {
  // Reset user states
  reset({ commit }) {
    commit('reset')
  },
  set({ commit }) {
    commit('set')
  },
  async selectReward({ commit, rootState }, rewards) {
    await commit('setRewards', rewards)
    // auto select reward
    for (const rwd of rewards) {
      if (rwd.running_balance > 0 && rwd.applicable_amount > 0) {
        await commit('addReward', rwd)
      }
    }

    // auto select reward
    await commit('calcTotalSelected')
  },
  async reselectReward({ state, commit }, rewards) {
    // set new rewards list
    await commit('setRewards', rewards)

    // remove selected reward if not exist in new rewards
    for (const sReward of state.selectedRewards) {
      // check sReward inNewRewards
      const inNewRewards = rewards.some(
        (r) => r.reward_id === sReward.reward_id
      )
      console.log('reselectReward inNewRewards:', inNewRewards)
      if (!inNewRewards) await commit('removeReward', sReward)
    }

    // reselect new rewards
    for (const rwd of rewards) {
      const inSelectRewards = state.selectedRewards.find(
        (r) => r.reward_id === rwd.reward_id
      )

      // console.log('inSelectRewards:', inSelectRewards)
      // remove selected reward if exist in new rewards
      if (inSelectRewards) await commit('removeReward', inSelectRewards)

      const inTrashRewards = state.trashRewards.some(
        (tr) => tr.reward_id === rwd.reward_id
      )
      console.log('reselectReward inTrashRewards:', inTrashRewards)

      if (
        rwd.applicable_amount > 0 &&
        rwd.running_balance > 0 &&
        !inTrashRewards
      ) {
        // console.log(`%c-> reselectReward newreward :`, 'color:#34D399;', rwd)
        await commit('addReward', rwd)
      }
    }

    //  recalc totalReward
    await commit('calcTotalSelected')
  },
  toggleReward({ commit, state }, reward) {
    const isSelectedReward = state.selectedRewards.some(
      (x) => x.reward_id === reward.reward_id
    )
    console.log('toggleReward isSelectedReward', isSelectedReward)
    if (isSelectedReward) {
      commit('removeReward', reward)
      commit('addTrashReward', reward)
    } else {
      commit('addReward', reward)
      commit('removeTrashReward', reward)
    }

    commit('calcTotalSelected')
  },
  async getRewards({ commit, state, dispatch, rootState }, mid) {
    const REWARDSVC = this.$config.REWARDSVC
    await commit('set', { rewardLoading: true })
    const subtotalBeforeDiscount = await rootState.cart.subtotalBeforeDiscount
    console.log(
      '%c-> getRewards subtotalBeforeDiscount:',
      'color:#34D399;',
      subtotalBeforeDiscount
    )

    const totalDiscountAmount = await rootState.cart.totalDiscountAmount
    const totalShipping = await rootState.shipping.total
    const totalShippingDisc = await rootState.shipping.totalAppliedDisc
    const tShipping = totalShipping - totalShippingDisc
    console.log(
      '%c-> getRewardEstimation tShipping:',
      'color:#34D399;',
      tShipping
    )

    let totalAmount = subtotalBeforeDiscount + tShipping - totalDiscountAmount
    const originTotalAmount = await rootState.cart.originTotalAmount
    if (originTotalAmount === 0) {
      totalAmount = 0
    }
    console.log('%c-> getRewards totalAmount:', 'color:#34D399;', totalAmount)

    await this.$axios
      .$get(
        `${REWARDSVC}/v1/reward-transactions/merchants/${mid}?amount=${totalAmount}`
      )
      .then(async (result) => {
        console.log('%c-> getRewards result:', 'color:#34D399;', result)
        if (result?.data?.length) {
          const rewards = await result.data
          if (state.selectedRewards.length === 0) {
            await dispatch('selectReward', rewards)
          }
          if (state.selectedRewards.length > 0) {
            await dispatch('reselectReward', rewards)
          }
        } else {
          await commit('reset')
        }

        await dispatch('cart/calcTotalAmount', null, { root: true })

        // console.log(
        //   '%c-> getRewards  cart/calcTotalAmount:',
        //   'color:#34D399;',
        //   rootState.cart.totalAmount
        // )
        await commit('set', { rewardLoading: false })
      })
      .catch((err) => {
        console.error('%c-> getRewards err:', 'color:#34D399;', err)
        commit('reset')
      })
  },
  async getRewardsFake({ commit, state, dispatch }, mid) {
    const url = `${window.location.protocol}://${window.location.hostname}:3003`
    const req = axios.create({ url })
    await req
      .get(`/fakeapi/reward-transaction_${mid}.json`)
      .then(async (res) => {
        console.log('%c-> getRewardsFake res.data:', 'color:#34D399;', res.data)
        const resdata = res?.data?.data || []
        if (resdata.length) {
          const rewards = resdata
          if (state.selectedRewards.length === 0) {
            await dispatch('selectReward', rewards)
          }

          if (state.selectedRewards.length > 0) {
            await dispatch('reselectReward', rewards)
          }
          await dispatch('cart/calcTotalAmount', null, { root: true })

          await commit('set', { rewardLoading: false })
        } else {
          commit('reset')
        }
      })
  },
  async getRewardEstimationFake({ commit, state, dispatch }, mid) {
    const url = `${window.location.protocol}://${window.location.hostname}:3003`
    const req = axios.create({ url })
    await req.get(`/fakeapi/reward-estimation_${mid}.json`).then((res) => {
      console.log(
        '%c-> getRewardEstimationFake reward_estimation:',
        'color:#34D399;',
        res.data.data.reward_estimation
      )
      commit('setRewardEstimation', res.data.data.reward_estimation)
    })
  },
  async getRewardEstimation({ commit, rootState, state }, mid) {
    const REWARDSVC = this.$config.REWARDSVC
    const subtotalBeforeDiscount = await rootState.cart.subtotalBeforeDiscount
    console.log(
      '%c-> getRewardEstimation subtotalBeforeDiscount:',
      'color:#34D399;',
      subtotalBeforeDiscount
    )

    const totalDiscountAmount = await rootState.cart.totalDiscountAmount
    const totalShipping = await rootState.shipping.total
    const totalShippingCost = await rootState.shipping.totalCost
    const totalShippingDisc = await rootState.shipping.totalAppliedDisc
    const tShipping = totalShipping - totalShippingDisc - totalShippingCost
    const totalReward = await state.totalSelected
    console.log(
      '%c-> getRewardEstimation totalReward:',
      'color:#34D399;',
      totalReward
    )

    let totalAmount =
      subtotalBeforeDiscount + tShipping - totalDiscountAmount - totalReward
    console.log('getRewardEstimation totalAmount:', totalAmount)

    const originTotalAmount = await rootState.cart.originTotalAmount
    if (originTotalAmount === 0) {
      totalAmount = 0
    }
    await commit('set', { rewardLoading: true })
    await this.$axios
      .$get(
        `${REWARDSVC}/v1/rewards/merchants/${mid}/estimation?amount=${totalAmount}`
      )
      .then(async (res) => {
        console.log('%c-> getRewardEstimation result:', 'color:#34D399;', res)
        if (res?.data?.reward_estimation) {
          await commit('setRewardEstimation', res.data.reward_estimation)
        } else {
          await commit('setRewardEstimation', 0)
        }
        await commit('set', { rewardLoading: false })
      })
      .catch((err) => {
        console.log('%c-> getRewards err:', 'color:#34D399;', err)
        commit('set', { rewardLoading: false })
        commit('reset')
        return err
      })
  },
  async applyRewards({ dispatch, commit, rootState }) {
    const reqFake = await rootState.cart.reqFake
    console.log('%c-> applyRewards : reqFake:', 'color:#34D399;', reqFake)
    // const cartFrom = await rootState.cart.cartFrom
    // const voucherFlikActive = await rootState.cart.voucherFlikActive
    const isRewardEnable = await rootState.config.isRewardEnable
    console.log('reqFake:', reqFake)
    console.log(
      '%c-> applyRewards - isRewardEnable:',
      'color:#34D399;',
      isRewardEnable
    )
    const mid = await rootState.cart.checkedItems[0].merchant_id
    console.log('%c-> applyRewards : mid:', 'color:#34D399;', mid)
    if (isRewardEnable && mid) {
      if (reqFake) {
        await dispatch('getRewardsFake', mid)
      } else {
        const voucherifyActive =
          this.$config.VOUCHERIFY_CAMP.replace(/\s+/g, '') !== ''

        await Promise.all([
          await dispatch('getRewards', mid),
          !voucherifyActive ? await dispatch('getRewardEstimation', mid) : null,
        ])
      }
    } else {
      await commit('reset')
      await dispatch('cart/calcTotalAmount', null, { root: true })
      console.log(
        '%c-> applyRewards - isRewardEnable:',
        'color:#34D399;',
        isRewardEnable
      )
    }
  },
}
