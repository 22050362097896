export default ({ app }, inject) => {
  // manually add the script to the DOM
  const s = document.createElement('script')
  s.id = 'xendit'
  s.defer = true
  s.async = true
  s.type = 'text/javascript'
  s.src = 'https://js.xendit.co/v1/xendit.min.js'
  // s.src = '/lib/xendit.min.js'
  document.body.appendChild(s)

  console.log('xendit JS from :', s.src)

  // if (window?.Xendit)
  //   window.Xendit.setPublishableKey(app.$config.XENDIT_PUBLISH_KEY)
  // else
  //   console.error(
  //     "Can't load Xendit library"
  //   )
}
