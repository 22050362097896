const initState = () => {
  return {
    hasAddress: false,
    s_province: null,
    s_regency: null,
    s_district: null,
    s_village: null,
    s_postalcode: null,
    default: {
      address_label: '',
      name: '',
      email: '',
      phone: '',
      status: '',
      type: '',
      address_1: '',
      address_2: '',
      address_3: '',
      address_4: '',
      city: '',
      state_province: '',
      country_code: '',
      postal_code: '',
      address_note: '',
      lat: 0,
      lng: 0,
    },
    provinceLoading: false,
    province: [],
    regencyLoading: false,
    regency: [],
    districtLoading: false,
    district: [],
    villageLoading: false,
    village: [],
    postalcodeLoading: false,
    postalcode: [],
    saved: [],
    address_label: '',
    geoaddress: null,
  }
}

export const state = () => initState()

export const mutations = {
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
  reset(state) {
    Object.assign(state, initState())
  },
  add(state, pars) {
    state.saved.push(pars)
  },
  reverse(state, pars) {
    state.saved = pars
    state.saved.reverse()
  },
  remove(state, label) {
    const itemIdx = state.raw.findIndex((x) => x.address_id === label)
    state.saved.splice(itemIdx, 1)
  },
  geocodeAddress(state, par) {
    state.geoaddress = par
  },
}

export const actions = {
  savedSort(ctx, pars) {
    ctx.commit('reverse', pars)
  },
  put(ctx, pars) {
    // console.log('PUT address:', pars)
    const SHOPPERSVC = this.$config.SHOPPERSVC
    return this.$axios.$put(`${SHOPPERSVC}/v1/shopper/address`, pars)
  },
  async delete({ commit }, addr) {
    console.log('addr:', addr)
    await commit(
      'set',
      {
        btnAddrLoading: true,
      },
      { root: true }
    )

    const tlabel = addr.address_label
    const id = addr.address_id
    const SHOPPERSVC = this.$config.SHOPPERSVC
    await this.$axios
      .$delete(`${SHOPPERSVC}/v1/shopper/address?address_id=${id}`)
      .then((res) => {
        console.log('DEL address response', res)
        this.$toast.info(`Address ${tlabel} deleted`)
      })
      .catch((err) => {
        console.log('error', err)
      })
    await commit(
      'set',
      {
        btnAddrLoading: false,
      },
      { root: true }
    )
  },
  async getProvince(ctx, name = 'all') {
    await ctx.commit('set', { provinceLoading: true })
    const pref = this.$config.LOCATIONSVC
    const url =
      name === 'all' ? `${pref}/province` : `${pref}/province?name=${name}`
    await this.$axios
      .$get(url)
      .then((res) => {
        console.log('res getProvince', res)
        if (res.data.length === 1) {
          ctx.commit('set', {
            s_province: {
              code: res.data[0].code,
              name: res.data[0].name,
            },
          })
        }
        if (name === 'all') {
          const listdata = res.data.map((x) => {
            return { code: x.code, name: x.name }
          })
          // set list of provinces
          ctx.commit('set', {
            province: listdata,
          })
        }
      })
      .catch((err) => {
        console.log('error', err)
      })
    // setTimeout((async) => {
    //   ctx.commit('set', { provinceLoading: false })
    // }, 3000)
    await ctx.commit('set', { provinceLoading: false })
  },
  async getRegencyByName(ctx, name = 'all') {
    await ctx.commit('set', { regencyLoading: true })
    const pref = this.$config.LOCATIONSVC
    await this.$axios
      .$get(`${pref}/regency?name=${name}`)
      .then((res) => {
        console.log('res getRegencyByName', res)
        if (res.data.length === 1) {
          ctx.commit('set', {
            s_regency: {
              code: res.data[0].code,
              name: res.data[0].name,
            },
          })
        }
        if (name === 'all') {
          const listdata = res.data.map((x) => {
            return { code: x.code, name: x.name }
          })
          // set list of provinces
          ctx.commit('set', {
            province: listdata,
          })
        }
      })
      .catch((err) => {
        console.log('error', err)
      })
    // setTimeout((async) => {
    //   ctx.commit('set', { provinceLoading: false })
    // }, 3000)
    await ctx.commit('set', { provinceLoading: false })
  },
  async getRegencyByParent(ctx, pcode) {
    await ctx.commit('set', { regencyLoading: true })
    const pref = this.$config.LOCATIONSVC
    await this.$axios
      .$get(`${pref}/regency/parent?code=${pcode}`)
      .then((res) => {
        console.log('res getRegencyByParent', res)
        const listdata = res.data.map((x) => {
          return { code: x.code, name: x.name }
        })
        ctx.commit('set', {
          regency: listdata,
        })
      })
      .catch((err) => {
        console.log('error', err)
      })
    // setTimeout((async) => {
    //   ctx.commit('set', { regencyLoading: false })
    // }, 3000)
    await ctx.commit('set', { regencyLoading: false })
  },
  async getDistrictByParent(ctx, pcode) {
    const pref = this.$config.LOCATIONSVC
    await this.$axios
      .$get(`${pref}/district/parent?code=${pcode}`)
      .then((res) => {
        console.log('res getDistrictByParent', res)
        const listdata = res.data.map((x) => {
          return { code: x.code, name: x.name }
        })
        ctx.commit('set', {
          district: listdata,
        })
      })
      .catch((err) => {
        console.log('error', err)
      })
  },
  async getVillageByParent(ctx, pcode) {
    const pref = this.$config.LOCATIONSVC
    await this.$axios
      .$get(`${pref}/village/parent?code=${pcode}`)
      .then((res) => {
        console.log('res getVillageByParent', res)
        const listdata = res.data.map((x) => {
          return { code: x.code, name: x.name }
        })
        ctx.commit('set', {
          village: listdata,
        })
      })
      .catch((err) => {
        console.log('error', err)
      })
  },
  async getPostalCode(ctx, keyword) {
    await ctx.commit('set', { postalcodeLoading: true })

    await this.$axios
      .get(`https://kodepos.vercel.app/search/?q=${keyword}`)
      .then((res) => {
        console.log('res getPostalCode', res)
        if (res.data.status) {
          ctx.commit('set', {
            postalcode: res.data.data,
          })
        } else {
          this.$toast.info('Postal code not found, please fill it manually')
          ctx.commit('set', {
            postalcode: [],
          })
        }
      })
      .catch((err) => {
        console.log('error', err)
      })
    await ctx.commit('set', { postalcodeLoading: false })
  },
}
