
export default {
  name: 'ErrFive',
  props: {
    error: {
      type: Object,
      default: () => {
        return {
          message: 'App error 500',
        }
      },
    },
  },
  computed: {
    pathCond() {
      return this.$isCustomDomain() ? '/' : '/'
    },
  },
}
