const initState = () => {
  return {
    phone: '',
    formattedPhone: '',
    expiresAt: 0,
    otp: '',
    device_id: '',
    merchant_id: '',
    app_id: 'DEVFLIKAK7LiE5k',
    err: null,
    OTPLoading: false,
    enableResend: false,
  }
}

export const state = () => initState()

export const mutations = {
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
  reset(state) {
    Object.assign(state, initState())
  },
}

export const actions = {
  async send(ctx, pars) {
    const AUTHSVC = this.$config.AUTHSVC
    return await this.$axios.$post(
      `${AUTHSVC}/v1/authentication/otp/send`,
      pars
    )
  },
  async validate(ctx, pars) {
    await ctx.commit('set', {
      OTPLoading: false,
    })
    const AUTHSVC = this.$config.AUTHSVC
    await this.$axios
      .$post(`${AUTHSVC}/v1/authentication/otp/validate`, pars)
      .then((res) => {
        ctx.commit(
          'set',
          {
            btnPhoneLoading: false,
          },
          { root: true }
        )
      })
      .catch((err) => {
        console.error('err:', err)
        ctx.commit('set', {
          OTPLoading: false,
        })
      })
  },
  clear(ctx) {
    ctx.commit('set', {
      phone: '',
      formattedPhone: '',
      expiresAt: 0,
      requestAt: 0,
      otp: '',
      err: null,
    })
  },
}
