const initState = () => {
  return {
    id: '',
    sku: '',
    name: '',
    slug: '',
    description: '',
    status: '',
    variants: [],
    use_variant: false,
    items: [],
    category: '',
    tags: '',
    weight: 1000,
    volume: {
      length: 150,
      width: 300,
      height: 10,
    },
    image_url: 'dummy',
    assets: [
      {
        height: 300,
        width: 200,
        image_url: 'dummy',
      },
    ],
    merchant: {
      id: '',
      name: '',
      slug: '',
      default_warehouse_info: {
        phone: '',
      },
    },
    stock_available: true,
    stock_qty: 0,
    price: {
      base_price: 0, // harga modal
      sell_price: 0, // harga jual
      discount_price: 0, // harga setelah diskon
      discount: false,
      currency: 'IDR',
    },
    created_at: 1648797336356, // timestamp
    created_by: '',
    updated_at: 1648797336356, // timestamp
    updated_by: '',
  }
}
// initial state
export const state = () => initState()

// mutations defined as object
export const mutations = {
  reset(state) {
    Object.assign(state, initState())
  },
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
    console.log('set product.id: ', state.id)
  },
}

// actions
export const actions = {
  // Reset user states
  reset({ commit }) {
    commit('reset')
  },
  set({ commit }) {
    commit('set')
  },
  getByItem(ctx, id) {
    // console.log('getByItem id:', id)
    const PRODUCTSVC = this.$config.PRODUCTSVC
    return this.$axios.$get(`${PRODUCTSVC}/v1/product?item_id=${id}`)
  },
}
