// import CryptoJS from 'crypto-js'
const initState = () => {
  return {
    selectWarehouse: null,
    pickup_warehouses: [],
  }
}

export const state = () => initState()

export const mutations = {
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
  reset(state) {
    Object.assign(state, initState())
  },
  selectWarehouse(state, param) {
    state.selectWarehouse = param
  },
  setWarehouses(state, param) {
    console.log('setWarehouses param:', param)
    state.pickup_warehouses = param
    if (state.selectWarehouse === null) state.selectWarehouse = param[0]
  },
}
