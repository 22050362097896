export default async ({ app, store, redirect, route }) => {
  const hasAddress = await store.state.shopper.hasAddress
  const hasPayment = await store.state.shopper.hasPayment
  const loggedIn = await store.state.auth.loggedIn
  const cartId = await store.state.cart.cartId
  const cartFrom = await store.state.cart.cartFrom
  const cartRedirBack = await store.state.cart.cartRedirBack
  const queryFrom = (await route.query.from) || ''
  console.log('queryFrom:', queryFrom)

  console.log('- guest middleware -')
  console.log('!app.$isCustomDomain():', !app.$isCustomDomain())
  console.log('loggedIn && hasAddress:', loggedIn && hasAddress)
  console.log('loggedIn && !hasAddress:', loggedIn && !hasAddress)
  console.log('- guest middleware -')

  console.log(
    'loggedIn && hasPayment && !app.$isCustomDomain():',
    loggedIn && hasPayment && !app.$isCustomDomain()
  )

  if (loggedIn && hasPayment && !app.$isCustomDomain()) {
    const query = await route.query
    const status = query?.result || ''
    const order_id = query?.order_id || ''
    console.log('GUEST mid route.query', query)

    // order-success redirect from midtrans
    if (status === 'success' && order_id !== '') {
      return await redirect(`/order-success?order_id=${order_id}`)
    }
    // order-failed redirect from midtrans
    if (status === 'failed' && order_id !== '') {
      return await redirect(`/order-failed?order_id=${order_id}`)
    }

    if (cartId.length && cartFrom.length) {
      const query = app.$serialize({
        back: cartRedirBack,
        from: queryFrom,
      })

      return redirect(`/cart/${cartId}?${query}`)
    }

    if (cartFrom === '' && queryFrom === '') {
      return await redirect(`/cart`)
    }

    if (cartFrom === '' && queryFrom !== '') {
      const query = app.$serialize({
        from: queryFrom,
      })
      console.log('query:', query)
      return await redirect(`/cart/?${query}`)
    }
  }
  return await app.$goToCart('replace')
  // End Redirect
}
