export default async function ({ store, redirect, $auth }) {
  const loggedIn = await $auth.loggedIn

  if (loggedIn) {
    let hasAccount = await store.state.shopper.hasAccount
    if (!hasAccount)
      await store.dispatch('shopper/get').then(async () => {
        hasAccount = await store.state.shopper.hasAccount
        console.log('%c-> middleware hasAccount ', 'color:#FB923C;', hasAccount)
        if (!hasAccount) {
          return redirect('/profile')
        }
      })
  }
}
