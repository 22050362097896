import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default (ctx, inject) => {
  console.log('ctx.$config.GTAG:', ctx.$config.GTAG)
  if (ctx.$config.GTAG === 'on') {
    Vue.use(
      VueGtag,
      {
        config: {
          id: ctx.$config.GA_MEASUREMENT_ID,
          send_page_view: true,
        },
        appName: 'FlikCheckout',
        pageTrackerScreenviewEnabled: false,
        pageTrackerEnabled: false,
        deferScriptLoad: true,
      },
      ctx.app.router
    )

    ctx.$gtag = Vue.$gtag
    inject('gtag', Vue.$gtag)
  }
}
