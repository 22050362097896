// import { phone } from 'phone'
// import { validate as uuidValidate } from 'uuid'

export default (context, inject) => {
  const app = context.app

  inject('trackFb', async (event_name, params) => {
    if (!app.$fb.isEnabled) {
      const shopperAcc = app.store.state.shopper.account
      const storePixelId =
        app.store.state.cart.storePixelId || app.$config.FB_PIXEL_ID
      app.$fb.setPixelId(storePixelId)
      app.$fb.setUserData({
        em: shopperAcc.email,
        fn: shopperAcc.first_name,
        ln: shopperAcc.first_name,
        ph: shopperAcc.phone,
      })
      app.$fb.enable()
    }
    if (app.$fb.isEnabled) {
      await app.$fb.track(event_name, params)
    }
  })

  inject('trackFbCustom', (event_name, params) => {
    if (!app.$fb.isEnabled) {
      const shopperAcc = app.store.state.shopper.account
      const storePixelId =
        app.store.state.cart.storePixelId || app.$config.FB_PIXEL_ID
      app.$fb.setPixelId(storePixelId)
      app.$fb.setUserData({
        em: shopperAcc.email,
        fn: shopperAcc.first_name,
        ln: shopperAcc.first_name,
        ph: shopperAcc.phone,
      })
      app.$fb.enable()
    }
    if (app.$fb.isEnabled) {
      let eventID = app.store.state.cart.cartId + `_${event_name}`
      if (event_name === 'PaymentPending') {
        eventID = app.store.state.order.last.id + `_${event_name}`
      }
      app.$fb.query('trackCustom', event_name, params, { eventID })
    }
  })

  inject('trackFbCart', (event_name) => {
    const cart = app.store.state.cart
    if (!app.$fb.isEnabled) {
      const shopperAcc = app.store.state.shopper.account
      const storePixelId = cart.storePixelId || app.$config.FB_PIXEL_ID
      app.$fb.setPixelId(storePixelId)
      app.$fb.setUserData({
        em: shopperAcc.email,
        fn: shopperAcc.first_name,
        ln: shopperAcc.first_name,
        ph: shopperAcc.phone,
      })
      app.$fb.enable()
    }
    if (app.$fb.isEnabled) {
      const cartItems =
        event_name === 'Purchase'
          ? app.store.state.payment.lastCartItems
          : cart.checkedItems
      const num_items = cartItems.length
      const lastOrder = app.store.state.order.last
      console.log('cartItems:', cartItems)
      if (num_items > 0) {
        let eventID = cart.cartId + `_${event_name}`
        if (event_name === 'Purchase') {
          eventID = lastOrder.id + `_${event_name}`
        }

        const value = cart.totalAmount
        const content_ids = cart.checkedIds
        const contents = app.$mapItemsCartGTM(cartItems)
        app.$fb.track(
          event_name,
          {
            transaction_id: lastOrder.transaction_id,
            order_id: lastOrder.id,
            content_category: cartItems[0].merchant_name,
            content_type: 'product',
            content_ids,
            contents,
            currency: 'IDR',
            num_items,
            value,
          },
          { eventID }
        )
      }
    }
  })

  inject('trackGA4', (event_name, params) => {
    if (app?.$gtag) {
      const usrPhone = app.store.state.shopper.account.phone
      Object.assign(params, {
        currency: 'IDR',
        user_phone: usrPhone,
      })
      app.$gtag.event(event_name, params)
    }
  })
  // track GA4 & FB Pixel with cart items
  inject('trackCart', (event_name, params) => {
    if (app?.$gtag && params.value > 0) {
      const checkedItems = app.store.state.cart.checkedItems
      const items = app.$mapItemsCartGTM(checkedItems)
      const usrPhone = app.store.state.shopper.account.phone
      Object.assign(params, {
        items,
        currency: 'IDR',
        user_phone: usrPhone,
      })

      const totalShipping = app.store.state.shipping.totalCost
      const couriers = app.store.state.shipping.scourier
        .map((e) => e.id)
        .join(',')

      if (totalShipping > 0) {
        Object.assign(params, {
          shipping: totalShipping,
          couriers,
        })
      }

      app.$gtag.event(event_name, params)
    }
  })
  // track GTM with cart items
  inject('trackGTMCart', (event_name, params) => {
    if (app?.$gtm) {
      const checkedItems = app.store.state.cart.checkedItems
      const usrPhone = app.store.state.shopper.account.phone
      const items = app.$mapItemsCartGTM(checkedItems)
      Object.assign(params, {
        items,
        currency: 'IDR',
        user_phone: usrPhone,
      })
      app.$gtm.push({ ecommerce: null })
      app.$gtm.push({
        event: event_name,
        ecommerce: params,
      })
    }
  })
  // track GA4 purchase
  inject('trackPurchaseGA4', () => {
    if (app?.$gtag) {
      const lastCartItems = app.store.state.payment.lastCartItems
      const items = app.$mapItemsCartGTM(lastCartItems)
      const lastTrxId = app.store.state.payment.lastTrxId
      const lastTrxStatus = app.store.state.payment.lastTrxStatus
      const lastOrderTotal = app.store.state.payment.lastOrderTotal
      const totalShipping = app.store.state.shipping.totalCost
      const couriers = app.store.state.shipping.scourier
        .map((e) => e.id)
        .join(',')
      const promotion_tier = app.store.state.promotion.redemption.promotion_tier
      const usrPhone = app.store.state.shopper.account.phone

      app.$gtag.event('purchase', {
        transaction_id: lastTrxId,
        user_phone: usrPhone,
        affiliation: '',
        value: lastOrderTotal,
        shipping: totalShipping,
        couriers,
        currency: 'IDR',
        coupon: promotion_tier?.name || '',
        payment_status: lastTrxStatus,
        items,
      })
    }
  })
  inject('trackPageView', (name = '') => {
    if (app?.$gtm) {
      app.$gtm.push({
        event: 'page_view',
        user_phone: app.store.state.shopper.account.phone,
        page_title: name.length ? name : context.route.name,
        page_path: context.route.path,
      })
    }
    // GA 4
    if (app?.$gtag) {
      app.$gtag.event('page_view', {
        user_phone: app.store.state.shopper.account.phone,
        page_title: name.length ? name : context.route.name,
        page_path: context.route.path,
      })
    }
  })
  inject('pushGTM', (params) => {
    if (app?.$gtm) {
      app.$gtm.push(params)
    }
  })
  inject('trackGTM', (event_name, sproduct, product, qty = 1) => {
    if (sproduct.varid !== '' && app?.$gtm) {
      console.log('trackGTM sproduct:', sproduct)
      // discount difference
      const discDiff = sproduct.price.discount
        ? sproduct.price.sell_price - sproduct.price.discount_price
        : 0
      // console.log('discDiff:', discDiff)
      // variants
      let svariant = ''
      if (sproduct?.takevariants) {
        svariant = sproduct.takevariants.join(', ').replace('|', ' ')
      }
      if (sproduct?.variant) {
        const arrVar = Object.values(sproduct.variant)
        svariant = arrVar.join(', ').replace('|', ' ')
      }
      if (sproduct?.qty) {
        qty = sproduct.qty
      }
      let variant_id = ''
      if (sproduct?.varid) {
        variant_id = sproduct.varid
      }
      if (sproduct?.id) {
        variant_id = sproduct.id
      }

      // console.log('svariant:', svariant)

      app.$gtm.push({
        event: event_name,
        ecommerce: {
          items: [
            {
              item_id: variant_id,
              item_name: product.name,
              affiliation: '',
              currency: 'IDR',
              discount: discDiff,
              item_brand: product.merchant.name,
              merchant: product.merchant.name,
              item_category: product.category,
              item_variant: svariant,
              price: sproduct.price.sell_price,
              value: sproduct.price.discount
                ? sproduct.price.discount_price
                : sproduct.price.sell_price,
              quantity: qty,
            },
          ],
        },
      })

      // End GA tracking
    }
  })
  inject('trackProductGA4', (event_name, sproduct, product, qty = 1) => {
    console.log('trackProductGA4 sproduct:', sproduct)
    // discount difference
    const discDiff = sproduct.price.discount
      ? sproduct.price.sell_price - sproduct.price.discount_price
      : 0
    // console.log('discDiff:', discDiff)
    // variants
    let svariant = ''
    if (sproduct?.takevariants) {
      svariant = sproduct.takevariants.join(', ').replace('|', ' ')
    }
    if (sproduct?.variant) {
      const arrVar = Object.values(sproduct.variant)
      svariant = arrVar.join(', ').replace('|', ' ')
    }
    if (sproduct?.qty) {
      qty = sproduct.qty
    }
    let variant_id = ''
    if (sproduct?.varid) {
      variant_id = sproduct.varid
    }
    if (sproduct?.id) {
      variant_id = sproduct.id
    }

    if (app?.$gtag) {
      const purchaseItem = {
        currency: 'IDR',
        value: sproduct.price.discount
          ? sproduct.price.discount_price
          : sproduct.price.sell_price,
        items: [
          {
            item_id: variant_id,
            item_name: product.name,
            affiliation: '',
            currency: 'IDR',
            discount: discDiff,
            item_brand: product.merchant.name,
            merchant: product.merchant.name,
            item_category: product.category,
            item_variant: svariant,
            price: sproduct.price.sell_price,
            quantity: qty,
          },
        ],
      }
      context.$gtag.event(event_name, purchaseItem)

      // End GA tracking
    }
  })
  inject('trackProductFB', (sproduct, product, qty = 1) => {
    console.log('trackProductFB sproduct:', sproduct)
    // discount difference
    const discDiff = sproduct.price.discount
      ? sproduct.price.sell_price - sproduct.price.discount_price
      : 0
    // console.log('discDiff:', discDiff)
    // variants
    let svariant = ''
    if (sproduct?.takevariants) {
      svariant = sproduct.takevariants.join(', ').replace('|', ' ')
    }
    if (sproduct?.variant) {
      const arrVar = Object.values(sproduct.variant)
      svariant = arrVar.join(', ').replace('|', ' ')
    }
    if (sproduct?.qty) {
      qty = sproduct.qty
    }
    let variant_id = ''
    if (sproduct?.varid) {
      variant_id = sproduct.varid
    }
    if (sproduct?.id) {
      variant_id = sproduct.id
    }

    if (!app.$fb.isEnabled) {
      const shopperAcc = app.store.state.shopper.account
      console.log(
        'app.store.state.cart.storePixelId:',
        app.store.state.cart.storePixelId
      )
      console.log('app.$config', app.$config.FB_PIXEL_ID)
      const storePixelId =
        app.store.state.cart.storePixelId || app.$config.FB_PIXEL_ID
      app.$fb.setPixelId(storePixelId)
      app.$fb.setUserData({
        em: shopperAcc.email,
        fn: shopperAcc.first_name,
        ln: shopperAcc.first_name,
        ph: shopperAcc.phone,
      })
      app.$fb.enable()
    }

    if (app.$fb.isEnabled) {
      app.$fb.track('ViewContent', {
        ontent_ids: [variant_id],
        content_category: product.category,
        content_name: product.name,
        content_type: 'product',
        currency: 'IDR',
        value: sproduct.price.discount
          ? sproduct.price.discount_price
          : sproduct.price.sell_price,
        contents: [
          {
            item_id: variant_id,
            item_name: product.name,
            affiliation: '',
            currency: 'IDR',
            discount: discDiff,
            item_brand: product.merchant.name,
            merchant: product.merchant.name,
            item_category: product.category,
            item_variant: svariant,
            price: sproduct.price.sell_price,
            quantity: qty,
          },
        ],
      })

      // End GA tracking
    }
  })
  inject('mapItemsCartGTM', (cartItems) => {
    return cartItems.map((i, idx) => {
      return {
        item_id: i.product_id,
        item_name: i.title,
        affiliation: '',
        currency: 'IDR',
        discount: i.is_discount ? i.subtotal_amount - i.total_amount : 0,
        index: idx,
        item_brand: i.merchant_name,
        merchant: i.merchant_name,
        item_category: '',
        item_variant: i.options.join(', '),
        price: i.subtotal_amount,
        value: i.total_amount,
        quantity: i.qty,
      }
    })
  })
  inject('truncatestr', (text, length, suffix) => {
    if (text.length > length) {
      return text.substring(0, length) + suffix
    } else {
      return text
    }
  })
  inject('filterOption', (productOpts) => {
    const items = productOpts.map((item) => {
      const varoptions = item.varoptions
      return varoptions
    })
    const flat = items.flat()
    const varOpts = [...new Set(flat)]
    return varOpts
  })
  inject('formatRupiah', (money) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    })
      .format(money)
      .replace(/\s+/g, '')
  })
  // inject('formatPhone', (str) => {
  //   const formatP = phone(str, { countryIso2: 'ID' })
  //   // console.log('formatP:', formatP)
  //   return formatP
  // })
  inject('validPhone', (str) => {
    // ref: https://www.huzefril.com/posts/regex/regex-nomor-handphone/
    // https://jdih.kominfo.go.id/produk_hukum/unduh/id/620/t/peraturan+menteri+komunikasi+dan+informatika+nomor+14+tahun+2018+tanggal+2+oktober+2018
    const validP = /^(\+62|62|0)8[1-9][0-9]{6,9}$/.test(str)
    // console.log('validPhone:', validP)
    return validP
  })
  inject('validUUID', (str) => {
    const regexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
    const valid = regexExp.test(str)
    return valid
  })
  inject('objEmpty', (obj) => {
    if (
      obj || // 👈 null and undefined check
      Object.keys(obj).length === 0 || // 👈 property check
      Object.getPrototypeOf(obj) === Object.prototype
    ) {
      return true
    } else {
      return false
    }
  })
  inject('serialize', (obj) => {
    const str = []
    Object.keys(obj).forEach((key) => {
      str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    })
    return str.join('&')
  })
  inject('getChild', (id, data) => {
    const p =
      (data || [])
        .flatMap((x) => x.children)
        .find((d) => d?.id.toLowerCase() === id.toLowerCase()) || null
    return p
  })
  inject('copyClipboard', async (data) => {
    if (navigator?.clipboard) await navigator.clipboard.writeText(data)
  })
  inject('isCustomDomain', () => {
    const hn = location.hostname
    const domains = app.$config.APP_DOMAIN
    const isCustom = domains.includes(hn)
    return isCustom
  })
  inject('isNodeDev', () => {
    return process.env.NODE_ENV === 'development'
  })
  inject('getCardImg', (card) => {
    let brand = 'unknown'
    if (card.type === 'card' || card.type === 'bcadebit') {
      brand = card.card_info.brand
    }
    return require(`@/assets/img/payment/${brand.toLowerCase()}.svg?inline`)
  })
  inject('scrollTo', (elmID) => {
    if (elmID) {
      const elem = document.getElementById(elmID)
      elem.scrollIntoView({ behavior: 'smooth' })
    }
  })
  inject('goToCart', async (act = '') => {
    const cartId = await app.store.state.cart.cartId
    const cartFrom = await app.store.state.cart.cartFrom
    const redirBack = await app.store.state.cart.cartRedirBack
    const reqFake = await app.store.state.cart.reqFake
    // const cartRedirBack = redirBack ? `?back=${redirBack}` : ''
    console.log('%c-> goToCart - reqFake ', 'color:#84CC16;', reqFake)
    console.log('%c-> goToCart - redirBack ', 'color:#84CC16;', redirBack)
    const curPath = app.router.history.current.path
    console.log('%c-> goToCart - curPath ', 'color:#84CC16;', curPath)

    const conCrtPReplce = cartId !== '' && cartFrom !== '' && act === 'replace'
    console.log(
      '%c-> goToCart - conCrtPReplce ',
      'color:#84CC16;',
      conCrtPReplce
    )
    const conCrtP = cartId !== '' && cartFrom !== '' && act === ''
    console.log('%c-> goToCart - conCrtP ', 'color:#84CC16;', conCrtP)

    const conCartReplce = cartId === '' && cartFrom === '' && act === 'replace'
    console.log(
      '%c-> goToCart - conCartReplce ',
      'color:#84CC16;',
      conCartReplce
    )

    const conCart = cartId === '' && cartFrom === '' && act === ''

    const query = {}
    if (reqFake) {
      Object.assign(query, { req: 'fake' })
    }

    if (redirBack !== '') {
      Object.assign(query, { back: redirBack })
    }

    console.log('%c-> goToCart - queryString ', 'color:#84CC16;', query)

    // return
    // eslint-disable-next-line no-unreachable
    if (conCrtPReplce && redirBack !== '') {
      app.router.replace({ path: `/cart/${cartId}`, query })
    } else if (conCrtPReplce && redirBack === '') {
      app.router.replace(`/cart/${cartId}`)
    } else if (conCrtP && redirBack === '') {
      app.router.push({ path: `/cart/${cartId}`, query })
    } else if (conCrtP && redirBack !== '') {
      // app.router.push(`/cart/${cartId}${cartRedirBack}`)
      app.router.push({ path: `/cart/${cartId}`, query })
    } else if (conCrtP && redirBack === curPath) {
      app.store.commit('cart/set', { cartRedirBack: '' })
      app.router.replace(`/cart/${cartId}`)
    } else if (conCart && redirBack === curPath) {
      app.store.commit('cart/set', { cartRedirBack: '' })
      app.router.push({ path: '/cart', query: {} })
    } else if (conCartReplce) {
      app.router.replace(`/cart`)
    } else {
      app.router.push({ path: '/cart', query: {} })
    }
  })
  inject('checkPromotion', async () => {
    const store = await app.store
    const totalOrder = await store.state.cart.totalAmount
    const voucherFlikActive = await store.state.cart.voucherFlikActive
    const mid = await store.state.cart.checkedItems[0].merchant_id

    console.log(
      '%c-> $checkPromotion | totalOrder BEFORE promotion/validatePromotion',
      'color:#84CC16;',
      totalOrder
    )

    await store.commit('promotion/resetValidate')
    // dont auto validate promotion if has voucher code (shopify)
    if (
      app.$config.VOUCHERIFY_CAMP.replace(/\s+/g, '') !== '' &&
      voucherFlikActive
    ) {
      // get campaign detail
      await store.dispatch('campaign/get', {
        id: app.$config.VOUCHERIFY_CAMP,
      })

      // promotion
      const campaignPromotion = await store.state.campaign.detail.promotion
      const validPromotion = await store.state.promotion.validate
      const tierId = (await campaignPromotion.tiers[0]?.id) || ''
      // console.log('tierId:', tierId)

      if (validPromotion.name === '') {
        // await this.resetCost()
        const loggedIn = await store.state.auth.loggedIn

        const shopperAcc = await store.state.shopper.account
        const sourceId = loggedIn ? shopperAcc.phone : app.$generateUUID()
        // console.log('sourceId:', sourceId)
        // const totalOrder = await store.state.cart.totalAmount
        const totalOrder = await store.state.cart.totalAmount
        // console.log('totalOrder BEFORE CALL validatePromotion:', totalOrder)
        await store.dispatch('promotion/validatePromotion', {
          tierId,
          sourceId,
          totalOrder,
        })
      }
      await store.dispatch('reward/getRewardEstimation', mid)
    }
  })
  inject(
    'sendEvent',
    async (
      ev = 'cart_last_activity',
      evname = '',
      changeto = '',
      item_id = ''
    ) => {
      const loggedIn = await app.store.state.auth.loggedIn
      if (loggedIn) {
        const store = await app.store
        const cart_id = await store.state.cart.cartId
        const evparams = {
          ev,
          evname,
          attr: {
            cart_id,
            cart_url: window?.location?.href || '',
            item_id,
            changeto: String(changeto),
          },
        }
        console.log('%c-> $sendEvent | evparams', 'color:#84CC16;', evparams)
        await store
          .dispatch('event/send', evparams)
          .then((result) => {
            console.log(
              '%c-> $sendEvent | dispatch result',
              'color:#84CC16;',
              result
            )
          })
          .catch((err) => {
            console.log('%c-> $sendEvent | dispatch err', 'color:#84CC16;', err)
          })
      } else {
        console.log(
          `%c-> $sendEvent | loggedIn ${evname}`,
          'color:#84CC16;',
          loggedIn
        )
      }
    }
  )
}
