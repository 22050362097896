import Tracker from '@openreplay/tracker'
import { v4 as uid } from 'uuid'

function defaultUserId() {
  return uid()
}

export default ({ app, isDev }, inject) => {
  inject('openReplayTrack', async () => {
    console.log('openReplayTrack isDev:', isDev)
    const store = await app.store
    const openReplayOn = await store.state.config.openReplayOn
    if (openReplayOn) {
      console.log('openReplay turn ON')
      const loggedIn = store.$auth.loggedIn
      const acc = store.state.shopper.account
      const userId = acc?.phone ? acc.phone : defaultUserId()
      const email = loggedIn ? acc.email : ''
      const name = loggedIn ? acc.first_name + ' ' + acc.last_name : ''
      if (app.$config.OPEN_REPLAY_KEY !== '' && !('__OPENREPLAY__' in window)) {
        const trackerConfig = {
          projectKey: app.$config.OPEN_REPLAY_KEY,
          ingestPoint: 'https://openreplay.useflik.com/ingest',
          __DISABLE_SECURE_MODE: isDev,
          obscureTextNumbers: false,
          obscureTextEmails: false,
          capturePerformance: true,
          onStart: ({ sessionID }) =>
            console.log('OpenReplay tracker started with session: ', sessionID),
          network: {
            sessionTokenHeader: false,
            ignoreHeaders: [],
            capturePayload: true,
          },
        }

        console.log('openReplayTrack trackerConfig:', trackerConfig)
        const tracker = new Tracker(trackerConfig)

        if (!tracker.isActive()) {
          tracker.setUserID(userId)
          tracker.start({
            userID: userId,
            metadata: {
              email: email,
              fullname: name,
            },
          })
        }
      }
    } else {
      console.log('openReplay turn OFF')
    }
  })
}
