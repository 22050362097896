
export default {
  name: 'ErrFourFive',
  props: {
    error: {
      type: Object,
      default: () => {
        return {
          message: 'Page is not exist or not found',
        }
      },
    },
  },
}
