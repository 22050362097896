const initState = () => {
  return {
    detail: {
      id: '',
      name: '',
      username: '',
      custom_domain: '',
      company_name: '',
      status: '',
      pic: [
        {
          first_name: '',
          last_name: '',
          phone: '',
          email: '',
        },
      ],
      address_1: '',
      address_2: '',
      city: '',
      state_province: '',
      postal_code: '',
      country_code: '',
      logo_url: '',
      config: {
        timezone: '',
        currency: '',
      },
      default_warehouse_info: {
        id: '',
        warehouse_code: '',
        name: '',
        phone: '',
        postal_code: '',
      },
    },
    products: [],
    lastPage: false,
    isError: false,
  }
}
// initial state
export const state = () => initState()

// mutations defined as object
export const mutations = {
  reset(state) {
    Object.assign(state, initState())
  },
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
  more(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => {
      state[key] = [...state[key], ...params[key]]
    })
  },
}

// actions
export const actions = {
  // Reset user states
  reset({ commit }) {
    commit('reset')
  },
  set({ commit }) {
    commit('set')
  },
  getDetail(ctx, mname) {
    const MERCHANTSVC = this.$config.MERCHANTSVC
    // this.$axios.setHeader('X-MID', mid)
    return this.$axios
      .$get(`${MERCHANTSVC}/v1/merchant/?username=${mname}`)
      .then((result) => {
        console.log('merchant getDetail result:', result)
        if (result) {
          if (result?.data) {
            ctx.commit('set', { detail: result.data })
          }
          ctx.commit('set', { isError: false })
        } else {
          ctx.commit('set', { isError: true })
        }
      })
      .catch((err) => {
        console.log('getDetail err:', err)
        ctx.commit('set', { isError: true })
      })
  },
  getDetailByDomain({ commit }, domain) {
    const MERCHANTSVC = this.$config.MERCHANTSVC
    // this.$axios.setHeader('X-MID', mid)
    return this.$axios
      .$get(`${MERCHANTSVC}/v1/merchant/?custom_domain=${domain}`)
      .then((result) => {
        console.log('merchant getDetailByDomain result:', result)
        if (result?.data) {
          commit('set', { detail: result.data })
        }
      })
      .catch((err) => {
        console.log('getDetailByDomain err:', err)
      })
  },
  getProducts(ctx, mname) {
    const PRODUCTSVC = this.$config.PRODUCTSVC
    return this.$axios
      .$get(
        `${PRODUCTSVC}/v1/merchant/?merchant_username=${mname}&queries[rows]=100&queries[page]=1&hide_platform=1`
      )
      .then((result) => {
        console.debug('getProducts merchant result:', result)
        if (result?.data) {
          ctx.commit('set', { products: result.data })
        }
      })
      .catch((err) => {
        console.error('getProducts merchant err:', err)
      })
  },
  loadMoreProduct(ctx, payload) {
    const PRODUCTSVC = this.$config.PRODUCTSVC
    const totalRows = 100
    // this.$axios.setHeader('X-MID', mid)
    return new Promise((resolve, reject) => {
      return this.$axios
        .$get(
          `${PRODUCTSVC}/v1/merchant/?merchant_username=${payload.mname}&queries[rows]=${totalRows}&queries[page]=${payload.page}&hide_platform=1`
        )
        .then((result) => {
          if (result?.data) {
            ctx.commit('more', { products: result.data })
            ctx.commit('set', { lastPage: result?.data.length < totalRows })
            resolve(result)
          } else {
            reject(result)
          }
        })
        .catch((err) => {
          console.log('err:', err)
        })
    })
  },
}
