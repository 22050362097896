export default async function ({ store, redirect, $auth }) {
  // check if formattedPhone number submitted
  const formattedPhone = await store.state.otp.formattedPhone
  const expiresAt = await store.state.otp.expiresAt
  const bypassPhoneInput = await store.state.cart.bypass_phone_input

  console.log('expiresAt:', expiresAt)

  if (formattedPhone === '' && !bypassPhoneInput) {
    return redirect('/')
  }
  if (expiresAt === 0 && !bypassPhoneInput) {
    return redirect('/')
  }
  if ($auth.loggedIn) {
    return redirect('/profile')
  }

  // End Redirect
}
