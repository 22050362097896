import Vue from 'vue'
import InfiniteLoading from 'vue-infinite-loading'

Vue.component('InfiniteLoading', InfiniteLoading)
Vue.use(InfiniteLoading, {
  slots: {
    noMore: '', // you can pass a string value
    noResults: '', // you can pass a string value
  },
})
