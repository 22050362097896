// import axios from 'axios'

const initState = () => {
  return {
    total: 0,
    totalCost: 0,
    totalDiscount: 0,
    totalAppliedDisc: 0,
    scourier: [],
    couriers: [],
    gcosts: [],
    costs: [],
    lowestCost: null,
    shipDiscount: [],
    rateLoading: true,
    rateAct: '',
  }
}

export const state = () => initState()

export const mutations = {
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
  reset(state) {
    Object.assign(state, initState())
  },
  resetCost(state) {
    // di disable karena untuk fungsi auto select setelah paymet
    // state.scourier = []
    state.costs = []
    state.totalCost = 0
    state.total = 0
    state.shipDiscount = []
    state.totalDiscount = 0
    state.totalAppliedDisc = 0
    state.lowestCost = null
  },
  resetSelectedCourier(state) {
    state.costs = []
    state.scourier = []
    state.shipDiscount = []
    state.totalDiscount = 0
    state.totalAppliedDisc = 0
    state.lowestCost = null
    state.rateAct = ''
  },
  pushCourier(state, param) {
    state.scourier.push(param)
  },
  replaceCourier(state, whid) {
    state.scourier = state.scourier.filter((x) => x.warehouse_id !== whid)
  },
  removeCourier(state, whid) {
    state.scourier = state.scourier.filter((x) => x.warehouse_id !== whid)
    state.shipDiscount = state.shipDiscount.filter(
      (x) => x.warehouse_id !== whid
    )
  },
  calcCost(state) {
    const uniquePrice = [
      ...new Map(state.scourier.map((i) => [i.warehouse_id, i])).values(),
    ]
    const courierPrice = []
    for (const sc of uniquePrice) {
      courierPrice.push(sc.price)
    }
    if (courierPrice.length > 0) {
      const totalShip = courierPrice.reduce((total, price) => {
        return total + price
      })
      state.total = totalShip
      if (state.totalDiscount > 0) {
        const s = totalShip - state.totalAppliedDisc
        state.totalCost = s > 0 ? s : 0
      } else {
        state.totalCost = totalShip
        state.totalDiscount = 0
        state.totalAppliedDisc = 0
      }
    } else {
      state.totalCost = 0
      state.total = 0
      state.totalDiscount = 0
      state.totalAppliedDisc = 0
    }
  },
  calcTotalDiscount(state) {
    let sum = 0
    let sumApplied = 0
    for (const x of state.shipDiscount) {
      sum += x.discount_amount
      sumApplied += x.applied_amount
    }
    state.totalDiscount = sum
    state.totalAppliedDisc = sumApplied
    const s = state.total - sumApplied
    state.totalCost = s > 0 ? s : 0
  },
  addShipDiscount(state, discount) {
    state.shipDiscount.push(discount)
  },
  removeShipDiscount(state, id) {
    state.shipDiscount = state.shipDiscount.filter((x) => x.id !== id)
  },
  removeMerchantShipDiscount(state, merchant_id) {
    state.shipDiscount = state.shipDiscount.filter(
      (x) => x.merchant_id !== merchant_id
    )
  },
  resetShipDiscount(state) {
    state.shipDiscount = []
    state.totalDiscount = 0
    state.totalAppliedDisc = 0
  },
  setPickupCost(state) {
    state.totalCost = 0
    state.scourier = []
    state.lowestCost = null
    state.totalCost = 0
    state.total = 0
  },
}

export const actions = {
  selectCost({ state, commit }, { courier }) {
    // courier id by warehouse_id
    if (courier?.warehouse_id) {
      const itemIdx = state.scourier.findIndex(
        (x) => x.warehouse_id === courier.warehouse_id
      )
      // if courier price exist remove it first
      if (itemIdx > -1) {
        commit('removeCourier', courier.warehouse_id)
      }
    }

    // add courier price
    commit('pushCourier', courier)
    // recalculate total shipping cost
    commit('calcCost')
  },
  removeCost({ rootState, commit }, whid) {
    console.group('removeCost')
    const rawItems = rootState.cart.raw
    const cWh = []
    for (const ci of rawItems) {
      if (ci.warehouse_id === whid) {
        cWh.push(ci.warehouse_id)
      }
    }
    // only remove if warehouse count 1
    if (cWh.length === 1 || cWh.length === 0) {
      commit('removeCourier', whid)
      commit('calcCost')
    }
    console.groupEnd()
  },
  udpateShipCourier({ state, commit }, shipDisc) {
    const scr = state.scourier.find(
      (sc) => sc.warehouse_id === shipDisc.warehouse_id
    )
    if (scr) {
      const priceDiff = scr.price - shipDisc.applied_amount
      const discount_price = priceDiff < 0 ? 0 : priceDiff
      const newscr = { ...scr, discount_price }
      commit('replaceCourier', shipDisc.warehouse_id)
      commit('pushCourier', newscr)
    }
  },
  resetCost({ commit }) {
    commit('resetCost')
    commit('resetShipDiscount')
  },
  getCourier(ctx) {
    // get cookie
    // const couriers_c = this.$cookie.get('couriers')
    // if cookie valid
    // if (couriers_c && ctx.state.couriers.length > 0) {
    //   return true
    // } else {
    const SHIPPINGSVC = this.$config.SHIPPINGSVC
    this.$axios
      .$get(`${SHIPPINGSVC}/v1/couriers`)
      .then((res) => {
        const couriers = []
        // get Enabled courier only
        for (const c of res.data) {
          if (c.is_enabled) couriers.push(c)
        }
        ctx.commit('set', { couriers })
        // this.$cookie.set('couriers', true, {
        //   path: '/',
        //   maxAge: 3600 * 6, // 6 hours lifetime
        // })
      })
      .catch((err) => {
        console.error('getCourier err:', err)
      })
    // }
  },
  async getRate({ dispatch, commit, rootState, state }, { par, warehouse_id }) {
    // const state = ctx.state
    await commit('set', { rateLoading: true })
    await commit('set', { rateAct: 'start' })

    const SHIPPINGSVC = this.$config.SHIPPINGSVC
    const payOneTime = rootState.payment.payOneTime
    console.log('payOneTime:', payOneTime)
    const defPayment = rootState.shopper.defPayment
    console.log('defPayment:', defPayment)
    let payment_type = ''
    if (defPayment) {
      payment_type = defPayment.type
    }
    if (payOneTime) {
      payment_type = payOneTime.id
    }
    if (payment_type !== '') Object.assign(par, { payment_type })

    const cart_id = await rootState.cart.cartId
    if (cart_id) Object.assign(par, { cart_id })

    console.log('getRate par:', par)

    await this.$axios
      .$post(`${SHIPPINGSVC}/v1/rates/merchant`, par)
      .then(async (res) => {
        if (res?.data?.length > 0) {
          console.log('%c-> getRate - res.data ', 'color:#FF95ED;', res.data)
          await dispatch('buildCourierCost', {
            shipRates: res.data,
            warehouse_id,
          })
          console.log('%c-> getRate ', 'color:#FF95ED;', state)
        } else {
          commit('resetSelectedCourier')
          commit('set', { rateLoading: false })
        }
      })
      .catch((err) => {
        commit('resetSelectedCourier')
        commit('set', { rateLoading: false })
        commit('set', { rateAct: '' })
        console.error('err:', err)
      })
    await commit('set', { rateLoading: false })
    await commit('set', { rateAct: 'done' })
  },
  async getRateAutoSelect(
    { commit, dispatch, state, rootState },
    { par, warehouse_id }
  ) {
    await commit('set', { rateLoading: true })
    await commit('set', { rateAct: 'start' })
    await commit('set', { confirmPayLoading: true }, { root: true })

    const SHIPPINGSVC = this.$config.SHIPPINGSVC
    console.log(`getRateAutoSelect ${warehouse_id} par:`, par)
    const payOneTime = rootState.payment.payOneTime
    console.log('payOneTime:', payOneTime)
    const defPayment = rootState.shopper.defPayment
    console.log('defPayment:', defPayment)
    let payment_type = ''
    if (defPayment) {
      payment_type = defPayment.type
    }
    if (payOneTime) {
      payment_type = payOneTime.id
    }
    if (payment_type !== '') Object.assign(par, { payment_type })

    const cart_id = await rootState.cart.cartId
    if (cart_id) Object.assign(par, { cart_id })

    console.log(`%c-> getRateAutoSelect param:`, 'color:#FF95ED;', par)
    await this.$axios
      .$post(`${SHIPPINGSVC}/v1/rates/merchant`, par)
      .then(async (res) => {
        console.log(`getRateAutoSelect ${warehouse_id} res:`, res)
        console.log(
          `%c-> getRateAutoSelect for ${warehouse_id} `,
          'color:#FF95ED;',
          res
        )
        if (res.data.length > 0) {
          await dispatch('buildCourierCost', {
            shipRates: res.data,
            warehouse_id,
          })
          // dont replace scourier
          if (state.lowestCost !== null && state.scourier.length === 0) {
            await dispatch('selectCost', { courier: state.lowestCost })
          }
          const totalCost = await state.totalCost
          console.log(
            '%c-> getRateAutoSelect - totalShippingCost ',
            'color:#FF95ED;',
            totalCost
          )
          // add total shipping cost to totalAmount
          await dispatch('applyShipDiscount', {
            warehouse_id: warehouse_id,
            merchant_id: par.merchant_id,
          })
          await dispatch('reward/applyRewards', null, { root: true })
        } else {
          commit('resetSelectedCourier')
          commit('set', { costs: [], rateLoading: false })
        }
      })
      .catch((err) => {
        commit('resetSelectedCourier')
        commit('set', { rateLoading: false })
        commit('set', { rateAct: '' })
        commit('set', { confirmPayLoading: false }, { root: true })
        console.error('getRateAutoSelect err:', err)
      })
    await commit('set', { rateLoading: false })
    await commit('set', { rateAct: 'done' })
    await commit('set', { confirmPayLoading: false }, { root: true })
  },
  async recheckRate({ state, commit, rootState, dispatch }, { product }) {
    const rState = await rootState
    const shipType = await rootState.shipType
    const defAddress = await rootState.shopper.defAddress
    console.log('recheckRate shipType:', shipType)
    if (shipType === 'delivery' && defAddress) {
      const scourier = await state.scourier
      const pid = product.product_id
      const warehouse_id = product.warehouse_id
      const merchant_id = product.merchant_id
      const wHasCourier = scourier.some(
        (sc) => sc.warehouse_id === warehouse_id
      )
      // abort recheckRate if warehouse has no courier
      if (!wHasCourier) {
        await dispatch('applyLowestCourierPrice', { warehouse_id })
      } else {
        const findSCourier = await scourier.find(
          (sc) => sc.warehouse_id === warehouse_id
        )
        console.log('recheckRate -> findSCourier:', findSCourier)
        // set loading state
        await commit('set', { rateLoading: true })
        await commit('set', { confirmPayLoading: true }, { root: true })
        // warehouse address postalcode lat long
        const origin = {
          postal_code: parseInt(product.warehouse_postal_code) || 0,
          lat: parseFloat(product.warehouse_lat) || 0.0,
          lng: parseFloat(product.warehouse_lng) || 0.0,
        }
        // user shipping address postalcode lat long
        const destination = {
          postal_code: parseInt(rState.shopper.defAddress.postal_code) || 0,
          lat: parseFloat(rState.shopper.defAddress.lat) || 0.0,
          lng: parseFloat(rState.shopper.defAddress.lng) || 0.0,
          city: rState.shopper.defAddress?.city || '',
        }

        const cartItems = rState.cart.items

        const courier = []
        for (const sc of scourier) {
          if (sc.warehouse_id === warehouse_id) courier.push(sc.id)
        }
        if (courier.length === 0) {
          commit('set', { rateLoading: false })
          commit('set', { confirmPayLoading: false }, { root: true })
          return false
        }
        // const couriers = [...new Set(courier)]

        const whCartItems = cartItems[warehouse_id] || null
        if (whCartItems === null) {
          dispatch('removeCost', product.warehouse_id)
          commit('set', { rateLoading: false })
          commit('set', { confirmPayLoading: false }, { root: true })
          return false
        }

        // start mapping cart items
        const items = []
        for (const i of whCartItems) {
          items.push({
            name: i.title,
            description:
              i.options.length > 0
                ? i.title + ' | ' + i.options.join(', ')
                : i.title,
            length: i.length,
            width: i.width,
            height: i.height,
            weight: i.weight,
            quantity: i.qty,
            value: i.sell_price,
          })
        } // end mapping cart items

        // construct parameter for API /rates
        const par = {
          origin,
          destination,
          merchant_id,
          items,
        }

        const payOneTime = await rootState.payment.payOneTime
        const defPayment = await rootState.shopper.defPayment
        let payment_type = ''
        if (defPayment) {
          payment_type = defPayment.type
        }
        if (payOneTime) {
          payment_type = payOneTime.id
        }
        if (payment_type !== '') Object.assign(par, { payment_type })

        const cart_id = await rootState.cart.cartId
        if (cart_id) Object.assign(par, { cart_id })

        const SHIPPINGSVC = this.$config.SHIPPINGSVC
        await commit('set', { rateAct: 'start' })
        await this.$axios
          .$post(`${SHIPPINGSVC}/v1/rates/merchant`, par)
          .then(async (res) => {
            console.log(
              '%c-> recheckRate - res.data ',
              'color:#FF95ED;',
              res.data
            )
            if (res?.data?.length > 0) {
              await dispatch('buildCourierCost', {
                shipRates: res.data,
                warehouse_id,
              })
              // if selectedCourier exist
              if (findSCourier) {
                const selectCosts = state.costs.find(
                  (x) => x.id === findSCourier.id
                )
                console.log('selectCosts:', selectCosts)
                if (selectCosts) {
                  await dispatch('selectCost', { courier: selectCosts })
                } else {
                  console.log('Prev scourier not exist: ', findSCourier)
                  await dispatch('selectCost', { courier: state.costs[0] })
                }
              } else {
                // if no selectedCourier give lowestCost courier
                console.log(
                  '%c-> recheckRate - if no selectedCourier ',
                  'color:#FF95ED;'
                )
                await dispatch('selectCost', { courier: state.lowestCost })
              }

              await dispatch('applyShipDiscount', {
                warehouse_id: warehouse_id,
                merchant_id,
              })
              await dispatch('reward/applyRewards', null, { root: true })
            } else {
              commit('resetSelectedCourier')
              commit('set', { rateLoading: false })
            }
          })
          .catch((err) => {
            console.error('recheckRate err:', err)
            const costErrRemove =
              scourier.find((sc) => sc.pwid === warehouse_id + '_' + pid) ||
              null
            // if product found
            if (costErrRemove !== null) {
              dispatch('removeCost', product.warehouse_id)
            }
            commit('set', { rateAct: 'done', rateLoading: false })
          })
        console.groupEnd()
        await commit('set', { rateLoading: false, rateAct: 'done' })
        await commit('set', { confirmPayLoading: false }, { root: true })
      }
    } // end shipType delivery
    if (shipType === 'pickup') {
      console.log('%c-> NO recheckRate ', 'color:#FF95ED;')
      await commit('setPickupCost')
      await dispatch('reward/applyRewards', null, { root: true })
      await dispatch('cart/calcTotalAmount', null, { root: true })
    }
  },
  async groupRecheckRate({ dispatch, commit, rootState, state }) {
    console.group('== START groupRecheckRate ==')

    await commit('set', { rateAct: '' })
    const cartItems = rootState.cart.items
    // check if selected courier price = warehouse selected
    const scourier = await state.scourier
    const wchecked = await rootState.cart.wchecked
    const shipType = await rootState.shipType

    console.log('%c-> groupRecheckRate scourier:', 'color:#FF95ED;', scourier)

    console.log('%c-> groupRecheckRate shipType', 'color:#FF95ED;', shipType)

    if (shipType === 'delivery') {
      if (scourier.length > 0) {
        console.log(
          '%c-> groupRecheckRate scourier.len > 0',
          'color:#FF95ED;',
          scourier
        )

        for (const c of scourier) {
          const inWchecked = await wchecked.includes(c.warehouse_id)
          console.log('c:', c)
          console.log('inWchecked:', inWchecked)
          if (!inWchecked) {
            await dispatch('removeCost', c.warehouse_id)
          }
        }
        // loop cartItems
        for (const [warehouse] of Object.entries(cartItems)) {
          console.log('[warehouse]:', [warehouse])
          await dispatch('recheckRate', {
            product: cartItems[warehouse][0],
            action: 'updateQty',
          })
        } // end cartItems
        // await commit('cart/setTotalDiscount', 0, { root: true })
        // await commit('cart/addTotalOrder', state.totalCost, { root: true })
      }

      // if only one merchant warehouse selected
      if (scourier.length === 0 && wchecked.length > 0) {
        for (const warehouse_id of wchecked) {
          console.log(
            '%c-> groupRecheckRate NO scourier will applyLowestCourierPrice for warehouse_id',
            'color:#FF95ED;',
            warehouse_id
          )

          await dispatch('applyLowestCourierPrice', { warehouse_id })
        }
      }
    }
    if (shipType === 'pickup') {
      await commit('setPickupCost')
      await dispatch('reward/applyRewards', null, { root: true })
      await dispatch('cart/calcTotalAmount', null, { root: true })
    }
    const shipmentRequired = rootState.cart.shipment_required

    if (!shipmentRequired) {
      await dispatch('reward/applyRewards', null, { root: true })
      await dispatch('cart/calcTotalAmount', null, { root: true })
    }

    await commit('set', { confirmPayLoading: false }, { root: true })

    console.log('%c-> == END groupRecheckRate == ', 'color:#FF95ED;')
    console.groupEnd()
  },
  async checkWorkingHours({ commit }, params) {
    const SHIPPINGSVC = this.$config.SHIPPINGSVC
    return await this.$axios.$post(
      `${SHIPPINGSVC}/v1/working_hours/merchant`,
      params
    )
  },
  async trackingOrder(ctx, trxId) {
    const SHIPPINGSVC = this.$config.SHIPPINGSVC
    return await this.$axios.$get(
      `${SHIPPINGSVC}/v1/trackings?flik_order_id=${trxId}`
    )
  },
  async applyShipDiscount(
    { commit, dispatch, state, rootState },
    { warehouse_id, merchant_id }
  ) {
    await commit('set', { confirmPayLoading: true }, { root: true })
    if (rootState.promotion.validate.discount_amount > 0) {
      await dispatch('promotion/resetPromoValidate', '', { root: true })
    }
    const checkedItems = await rootState.cart.checkedItems.filter(
      (ci) => ci.merchant_id === merchant_id
    )
    const checkedItemsL = checkedItems.length
    if (checkedItemsL > 0) {
      const param = {
        order: {
          items: checkedItems.map((ci) => {
            return {
              id: ci.product_id,
              ext_id: ci.merchant_item_id,
              qty: ci.qty,
              is_ppo: ci.is_ppo ? ci.is_ppo : false,
            }
          }),
        },
      }
      const PROMOSVC = this.$config.PROMOSVC
      await this.$axios
        .$post(`${PROMOSVC}/v1/mid/${merchant_id}`, param)
        .then(async (result) => {
          const shipDiscount = result?.data || []
          const shipDiscountL = shipDiscount.length

          console.log(
            '%c-> applyShipDiscount BEFORE - totalShippingCost ',
            'color:#FF95ED;',
            state.totalCost
          )

          if (checkedItemsL > 0 && shipDiscountL > 0) {
            for (const dscShip of shipDiscount) {
              if (dscShip.type === 'shipping') {
                console.log(
                  '%c-> discShipping ',
                  'color:#FF95ED;',
                  dscShip.name
                )
                const discPrice = dscShip.discount_amount
                const checkExist = state.shipDiscount.some(
                  (sd) => sd.id === dscShip.id
                )
                // console.log('checkExist:', checkExist)

                const scourier = state.scourier.find(
                  (sc) => sc.warehouse_id === warehouse_id
                )
                // console.log('scourier:', scourier)

                const courierPrice = scourier ? scourier.price : 0

                // console.log('discPrice:', discPrice)
                // console.log('courierPrice:', courierPrice)
                // if shipping discount < courier price
                const diffPrice = courierPrice - discPrice
                // console.log('diffPrice:', diffPrice)
                // console.log(' discPrice > diffPrice:', discPrice > diffPrice)

                let applied_amount = discPrice

                if (diffPrice < 0) {
                  applied_amount = courierPrice
                }

                // console.log('applied_amount:', applied_amount)

                const discount = {
                  warehouse_id,
                  merchant_id,
                  applied_amount,
                  ...dscShip,
                }
                // console.log('remap discount:', discount)
                if (!checkExist) {
                  await commit('addShipDiscount', discount)
                  await dispatch('udpateShipCourier', discount)
                  await commit('calcTotalDiscount')
                } else {
                  await commit('removeShipDiscount', discount.id)
                  await commit('addShipDiscount', discount)
                  await dispatch('udpateShipCourier', discount)
                  await commit('calcTotalDiscount')
                }
              }
            }
          }
          // console.log('shipDiscountL:', shipDiscountL)
          if (shipDiscountL === 0) {
            await commit('removeMerchantShipDiscount', merchant_id)
            await commit('calcTotalDiscount')
            await commit('calcCost')
          }

          console.log(
            '%c-> applyShipDiscount AFTER - totalShippingCost ',
            'color:#FF95ED;',
            state.totalCost
          )

          await dispatch('cart/calcTotalAmount', null, { root: true })

          await await this.$checkPromotion()
        })
        .catch((err) => {
          commit('set', { confirmPayLoading: false }, { root: true })
          console.error(`No discount for merchant: ${merchant_id}`)
          console.error('err:', err)
        })
    } else {
      console.log('Empty Cart')
    }
    await commit('set', { confirmPayLoading: false }, { root: true })
    console.groupEnd()
  },
  async applyLowestCourierPrice({ dispatch, rootState }, { warehouse_id }) {
    const shipmentRequired = rootState.cart.shipment_required
    console.log(
      '%c-> applyLowestCourierPrice - shipmentRequired: ',
      'color:#FF95ED;',
      shipmentRequired
    )

    if (shipmentRequired) {
      const defAddress = await rootState.shopper.defAddress
      // if user has no defAddress do nothing
      if (defAddress === null) return
      const citems = await rootState.cart.checkedItems
      const item = citems.find((ci) => ci.warehouse_id === warehouse_id)
      const merchant_id = item.merchant_id
      const whPostalCode = parseInt(item?.warehouse_postal_code) || 12240
      const usrPostalCode = parseInt(defAddress?.postal_code) || 12240
      const origin = {
        postal_code: whPostalCode,
        lat: parseFloat(item?.warehouse_lat) || 0.0,
        lng: parseFloat(item?.warehouse_lng) || 0.0,
      }
      const destination = {
        postal_code: usrPostalCode,
        lat: parseFloat(defAddress?.lat) || 0.0,
        lng: parseFloat(defAddress?.lng) || 0.0,
        city: defAddress?.city || '',
      }

      const items = []
      const witems = await rootState.cart.items[warehouse_id]
      for (const c of witems) {
        items.push({
          name: c.title,
          description:
            c.options.length > 0
              ? c.title + ' | ' + c.options.join(', ')
              : c.title,
          length: c.length,
          width: c.width,
          height: c.height,
          weight: c.weight,
          quantity: c.qty,
          value: c.sell_price,
        })
      }
      const par = {
        origin,
        destination,
        merchant_id,
        items,
      }
      await dispatch('getRateAutoSelect', { par, warehouse_id })
    }
  },
  async buildCourierCost({ commit, state }, { shipRates, warehouse_id, pid }) {
    const costs = []
    for (const r of shipRates) {
      const courier = {
        id: r.id,
        logo: r.courier_logo,
        price: r.price,
        title: r.title,
        duration_range: r.shipment_duration_range,
        duration_unit: r.shipment_duration_unit,
        discount_price: r.discount_price || 0,
        service_type: r.service_type,
        working_hour_info: r.working_hour_info,
        warehouse_id,
      }
      if (pid) Object.assign(courier, { pid, pwid: warehouse_id + '_' + pid })
      // if shipDiscount exist attach discount_price to costs
      if (state.shipDiscount.length > 0) {
        for (const sdc of state.shipDiscount) {
          const priceDiff = r.price - sdc.discount_amount
          const discount_price = priceDiff < 0 ? 0 : priceDiff
          Object.assign(courier, { discount_price })
        }
      }
      // if shipDiscount exist attach discount_price to costs
      // console.log('newcourier:', courier)
      costs.push(courier)
    }
    const min = (a, f) => a.reduce((m, x) => (m[f] < x[f] ? m : x))
    console.log('%c-> buildCourierCost - costs: ', 'color:#FF95ED;', costs)
    console.table(costs)
    if (costs.length > 0) {
      const lowestCost = min(costs, 'price')
      await commit('set', { costs, lowestCost })
    }

    if (costs.length === 0) {
      await commit('set', { costs: [], shipDiscount: [], scourier: [] })
    }
    await commit('set', { rateAct: 'done' })
  },
}
