const initState = () => {
  return {
    varid: '',
    sku: '',
    name: '',
    stock_available: true,
    urlReplaced: false,
    switchOpt: false,
    takevariants: [],
    variants: [],
    options: [],
    qty: 1,
    price: {
      base_price: 0,
      sell_price: 0,
      discount_price: 0,
      discount: false,
      currency: 'IDR',
      labels: {
        discount_percent: '',
      },
    },
  }
}

export const state = () => initState()

// initial state
// export const state = initState()

// mutations defined as object
export const mutations = {
  reset(state) {
    Object.assign(state, initState())
    // console.log('select product reset')
  },
  set(state, params) {
    // console.log('params', params)
    const keys = Object.keys(params)
    // console.log('keys', keys)
    keys.forEach((key) => (state[key] = params[key]))
  },
  resetVariant(state) {
    state.variants = []
  },
  selectVariant(state, { varname, varopt }) {
    const obj = {
      name: varname,
      option: varopt,
    }

    const filtered = state.variants.filter((item, index, arr) => {
      console.log('item', item)
      if (item.name === varname) {
        arr.splice(index, 1)
      }
      return arr
    })
    console.log('filtered', filtered)
    state.variants.push(obj)
  },
  removeTakeVariant(state, params) {
    // console.log('params:', params)
    const index = state.takevariants.indexOf(params)
    // console.log('removeTakeVariant index:', index)
    state.takevariants.splice(index, 1)
    console.log('state.switchOpt:', state.switchOpt)
  },
  resetTakeVariant(state, params) {
    // console.log('params:', params)
    const index = state.takevariants.indexOf(params)
    // console.log('removeTakeVariant index:', index)
    if (index > -1) state.takevariants = []
  },
  takeVariant(state, { varname, varopt, hasVarSingle }) {
    const option = varname + '|' + varopt

    state.takevariants.forEach((item, i, arr) => {
      if (item.split('|')[0] === varname) {
        state.takevariants.splice(i, 1)
        if (!hasVarSingle) state.switchOpt = true
      }
    })
    // console.log('taken', filtered)
    state.takevariants.push(option)
  },
}

// actions
export const actions = {
  // Reset user states
  reset({ commit }) {
    commit('reset')
  },
  set({ commit }) {
    commit('set')
  },
}
