
import { mapState } from 'vuex'
export default {
  data() {
    return {
      showDevMenu: false,
    }
  },
  computed: {
    ...mapState({
      loggedIn: (state) => state.auth.loggedIn,
    }),
  },
  methods: {
    emptyCart() {
      this.$store.dispatch('cart/empty')
      this.$store.dispatch('shipping/resetCost')
      this.$store.commit('shipping/resetSelectedCourier')
      this.$store.commit('reward/reset')
      this.$store.commit('set', { confirmPayLoading: false })
    },
    emptyCourier() {
      this.$store.commit('shipping/resetSelectedCourier')
      this.$store.dispatch('shipping/resetCost')
      this.$store.commit('shipping/calcCost')
      this.$store.dispatch('promotion/resetPromoValidate')
      this.$checkPromotion()
    },
    changeDevMenu() {
      this.showDevMenu = !this.showDevMenu
    },
    logout() {
      this.$auth.logout().then(() => {
        this.$store.commit('otp/set', {
          expiresAt: 0,
          requestAt: 0,
          phone: '',
          otp: '',
        })
      })
    },
  },
}
