// import axios from 'axios'

const initState = () => {
  return {
    redeemLoading: false,
    redemptions: [],
    order: {
      id: '',
      source_id: null,
      created_at: '',
      updated_at: null,
      status: '',
      amount: 0,
      discount_amount: 0,
      total_discount_amount: 0,
      total_amount: 0,
      applied_discount_amount: 0,
      total_applied_discount_amount: 0,
      metadata: {},
      customer: {},
      customer_id: '',
      object: 'order',
    },
  }
}
// initial state
export const state = () => initState()

// mutations defined as object
export const mutations = {
  reset(state) {
    Object.assign(state, initState())
  },
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
}

// actions
export const actions = {
  clear({ commit }) {
    commit('reset')
  },
  async stackRedemptions(ctx, param) {
    console.group('redemptions')
    return await this.$axios
      .$post(`/3rdparty/promo/v1/redemptions`, param)
      .catch((err) => {
        console.error('err:', err)
        const duration = 7000
        const rescode = err?.response?.data?.code
        if (rescode === 401) {
          console.error('err 401:', err?.response)
          if (this.$config.APP_DEBUG === 'on')
            this.$toast.error('Unauthorized', { duration })
        }
        if (rescode === 400) {
          console.error('err 400:', err?.response)
          if (this.$config.APP_DEBUG === 'on')
            this.$toast.error('Request invalid', { duration })
        }
        if (rescode === 429) {
          console.error('err 429:', err?.response)
          if (this.$config.APP_DEBUG === 'on')
            this.$toast.error('Voucher request limit', { duration })
        }
        console.groupEnd()
      })
  },
}
