/* eslint-disable no-unused-vars */
import { extend, configure } from 'vee-validate'
import { required, email, required_if } from 'vee-validate/dist/rules'
// import {
// isValidZIPWithComparison,
// isValidCellularNumber,
// isValidCCNumber,
// } from 'nusantara-valid'

// import { parsePhoneNumber } from 'awesome-phonenumber'

// const creditCard = require('credit-cards')
const luhn = require('luhn')
export default ({ $config: { DOMAIN_EMAIL_VALIDATION } }) => {
  configure({
    classes: {
      valid: 'is-valid',
      invalid: 'is-invalid',
      dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
    },
  })

  extend('required', {
    ...required,
    message: '{_field_} required',
  })

  extend('required_if', {
    ...required_if,
    message: '{_field_} required',
  })

  extend('email', {
    ...email,
    message: 'Valid {_field_} required',
  })

  extend('min', {
    validate(value, { length }) {
      return value.length >= length
    },
    params: ['length'],
    message: '{_field_} required min {length} chars',
  })

  extend('emaildomain', {
    validate(value) {
      const domainList = DOMAIN_EMAIL_VALIDATION.split(' ')
      console.log('domainList', domainList)
      const splitDomain = value.split('@')
      const getDomain = splitDomain[1].split('.')

      // check domain is include on list
      if (domainList.includes(getDomain[0])) {
        // counting dot on string domain
        const checkDot = splitDomain[1].match(/\./g).length

        // check if domain yahoo and . grether than 1
        if (value.includes('yahoo') && checkDot > 1) {
          return splitDomain[1].includes('co.id')
        }

        return splitDomain[1].includes('com')
      }
      return true
    },
    message: 'Email Domain not valid',
  })

  // extend('postcode', {
  //   validate(value, { provkey }) {
  //     return isValidZIPWithComparison(value, { provinceKey: provkey })
  //   },
  //   params: ['provkey'],
  //   message: 'Valid {_field_} required',
  // })

  extend('cardnumber', {
    validate(value) {
      console.log('value:', value)
      console.log('value:', value.length)
      return luhn.validate(value.replaceAll(' ', ''))
    },
    message: 'Valid card number required',
  })

  extend('phone', {
    validate(value) {
      const regex =
        /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
      const validP = regex.test(value)
      return validP
    },
    message: `isn't valid`,
  })
}
