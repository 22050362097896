// import CryptoJS from 'crypto-js'
const initState = () => {
  return {
    checkouts: [],
  }
}

export const state = () => initState()

export const mutations = {
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
  reset(state) {
    Object.assign(state, initState())
  },
  setShortLinks(state, params) {
    params.forEach((data) => {
      state.checkouts.push(data)
    })
  },
}

export const actions = {
  getShortLinks(ctx) {
    console.groupCollapsed('getAllShortLinkProducts')
    const product = ctx.rootState.product
    const merId = product.merchant.id

    const listNullLocal = []
    product.items.forEach((item) => {
      const params = {
        mid: merId,
      }
      const varid = item.varid

      Object.assign(params, {
        'items[]': varid + '|' + 1,
      })
      // serialize query string
      const qStr = this.$serialize(params)
      const checkoutUrl = window.location.origin + '/cart'
      const checkoutLink = checkoutUrl + '?' + qStr
      // const md5CheckoutLink = CryptoJS.MD5(checkoutLink).toString()
      // const isFoundLocal = ctx.state.checkouts.find((product) => {
      //   return product.id === md5CheckoutLink
      // })
      // condition for check found local
      // if (!isFoundLocal || ctx.state.checkouts.length === 0) {
      // listNullLocal.push(checkoutLink)
      // }
      listNullLocal.push(checkoutLink)
    })
    console.log('list not found on local', listNullLocal)
    if (listNullLocal.length > 0) {
      return this.$axios
        .$post(`shortener/v1/shortener`, {
          urls: listNullLocal,
        })
        .then((result) => {
          console.log('getShortLinks product result:', result)
          if (result?.data) {
            ctx.commit('setShortLinks', result.data)
          }
        })
        .catch((err) => {
          console.error('getShortLinks product err:', err)
        })
    }
    console.groupEnd()
  },
}
