// import CryptoJS from 'crypto-js'
const initState = () => {
  return {
    list: [],
  }
}

export const state = () => initState()

export const mutations = {
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
  reset(state) {
    Object.assign(state, initState())
  },
}

export const actions = {
  dummyPost(_ctx, params) {
    this.$axios
      .post('https://my-json-server.typicode.com/useflik/dummy/events', params)
      .then((result) => {
        console.log('dummyPost result:', result)
      })
      .catch((err) => {
        console.error('dummyPost err:', err)
        return true
      })
  },
  async send(_ctx, pars) {
    const CARTSVC = this.$config.CARTSVC
    await this.$axios
      .$post(`${CARTSVC}/events`, pars)
      .then((result) => {
        console.log('events/send result:', result)
      })
      .catch((err) => {
        console.error('events/send err:', err)
        return true
      })
  },
}
