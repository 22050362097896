
import ErrFour from '~/components/ErrFour.vue'
import ErrFourFive from '~/components/ErrFourFive.vue'
import ErrFive from '~/components/ErrFive.vue'
export default {
  name: 'Error',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  head() {
    return {
      bodyAttrs: {
        class: this.$device.isDesktop ? 'desktop' : 'mobile',
      },
      title: `Page ${this.error.statusCode}`,
    }
  },
  computed: {
    errorPage() {
      if (this.error.statusCode === 404 || this.error.statusCode === 400) {
        return ErrFour
      }
      if (this.error.statusCode === 405) {
        return ErrFourFive
      }
      // catch everything else
      return ErrFive
    },
  },
  mounted() {
    this.$openReplayTrack()
    // GA tracking
    this.$trackGA4('exception', { description: `${this.error.statusCode}` })
    // End GA tracking
  },
}
