// import HmacSHA256 from 'crypto-js/hmac-sha256'
import { v4 as uid } from 'uuid'

const initState = () => {
  return {
    // redeemLoading: false,
    detailLoading: false,
    detail: {
      id: '',
      name: '',
      campaign_type: '',
      type: '',
      auto_join: false,
      join_once: false,
      use_voucher_metadata_schema: false,
      active: false,
      metadata: {},
      created_at: '', // 2022-09-20T12:36:00.519Z
      creation_status: '', // DONE
      vouchers_generation_status: '', // DONE
      protected: false,
      promotion: {
        tiers: [],
        total: 1,
        has_more: false,
      },
      category_id: null,
      object: 'campaign',
    },
  }
}
// initial state
export const state = () => initState()

// mutations defined as object
export const mutations = {
  reset(state) {
    Object.assign(state, initState())
  },
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
  resetDetail(state) {
    state.detailLoading = false
    state.detail = initState().detail
  },
}

// actions
export const actions = {
  // Reset user states
  reset({ commit }) {
    commit('reset')
  },
  set({ commit }) {
    commit('set')
  },
  async get(ctx, { id }) {
    if (id === '') {
      console.error(`Campaign ID not found`)
      ctx.commit('reset')
      return
    }

    // const signature = HmacSHA256(uid(), this.$config.XSJSX).toString()
    // this.$axios.setHeader('X-Signature', signature)
    this.$axios.setHeader('X-Signature', uid(32))

    return await this.$axios
      .$get(`/campaigns/${id}`)
      .then((result) => {
        console.log('campaigns get result:', result)
        if (result?.active) {
          ctx.commit('set', { detail: result })
        }
      })
      .catch((err) => {
        console.log('err:', err)
        if (err?.response?.data?.message)
          console.error(err?.response?.data?.message)
      })
  },
}
