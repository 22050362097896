export default async function ({ store, redirect, $auth }) {
  const loggedIn = await $auth.loggedIn
  if (loggedIn) {
    await store.dispatch('shopper/get')
    const shipment_required = await store.state.cart.shipment_required
    let hasAddress = await store.state.shopper.hasAddress
    if (!hasAddress)
      await store.dispatch('shopper/get').then(async () => {
        hasAddress = await store.state.shopper.hasAddress
        console.log('%c-> middleware hasAddress ', 'color:#FB923C;', hasAddress)
        if (!hasAddress && shipment_required) {
          return redirect('/address/add')
        }
      })
  }
}
