import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
const ls = new SecureLS({ isCompression: false })

export default ({ store, app }) => {
  const isDevMode = app.$config.APP_DEV === 'api_dev'
  const isDebug = app.$config.APP_DEBUG === 'on'

  console.log('%c-> createPersistedState | isDebug', 'color:#2DD4BF;', isDebug)

  let key = 'flkd'
  if (isDebug && isDevMode) key = 'flkdbg'
  if (!isDebug && !isDevMode) key = 'flkprd'
  const opt = {
    key,
    paths: [
      'otp.phone',
      'otp.formattedPhone',
      'otp.device_id',
      'otp.expiresAt',
      'shopper.account',
      'shopper.config',
      'shopper.flags',
      'shopper.defAddress',
      'shopper.defPayment',
      'shopper.default_address',
      'shopper.default_payment',
      'shopper.saved_payments',
      'shopper.ewalletBalance',
      'shopper.hasAddress',
      'shopper.hasPayment',
      'cart',
      'shipping.scourier',
      'shipping.total',
      'shipping.totalCost',
      'shipping.totalAppliedDisc',
      'shipping.shipDiscount',
      'payment.default',
      'xendit.card',
      'xendit.cards',
      'payment.installmentInterval',
      'payment.methods',
      'payment.qrstr',
      'payment.vaNumber',
      'payment.vaExpiry',
      'payment.lastOrderTotal',
      'payment.lastTrxId',
      'payment.lastCartItems',
      'payment.paymentType',
      'payment.lastTrxStatus',
      'payment.payOneTime',
      'payment.selectedInstalment',
      'campaign.detail.promotion.tiers',
      'promotion.validate.valid',
      'promotion.validate.name',
      'promotion.redemption.id',
      'promotion.redemption.order.id',
      'promotion.redemption.promotion_tier.name',
      'voucher.promoCode',
      'voucher.promoLevel',
      'voucher.promoMid',
      'voucher.promoId',
      'voucher.listFlik',
      'voucher.listMerchant',
      'voucher.total',
      'voucher.totalDiscount',
      'voucher.selectedVoucher',
      // 'voucher.vouchers',
      'order.last',
      'order.failedReason',
      'appDomain',
      'merchant.detail.logo_url',
      'merchant.detail.custom_domain',
      'merchant.detail.username',
      'merchant.detail.pic',
      'shortlink',
      'cart.fromShopper',
      'ppo',
      'reward.selectedRewards',
      'shipType',
      'warehouse',
    ],
  }
  if (!isDebug) {
    opt.storage = {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key),
    }
  }
  createPersistedState(opt)(store)
}
