import * as configcat from 'configcat-js'

const initState = () => {
  return {
    isRewardEnable: true,
    promoPostPurchaseOffer: true,
    openReplayOn: true,
    promoEngine: false,
    enable3dsWhenLinkingCard: false,
  }
}

export const state = () => initState()

export const mutations = {
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
  reset(state) {
    Object.assign(state, initState())
  },
}

export const actions = {
  async getConfig({ commit }, featureKey) {
    if (this.$config.CONFIG_KEY.length > 0)
      try {
        const configCatClient = configcat.getClient(
          this.$config.CONFIG_KEY,
          // configcat.PollingMode.LazyLoad,
          configcat.PollingMode.AutoPoll,
          null
        )
        const value = await configCatClient.getValueAsync(featureKey, false)

        if (value) {
          console.log(`${featureKey} value`, value)
          const obj = {
            [featureKey]: value,
          }
          commit('set', obj)
        }
      } catch (err) {
        console.error('getConfigCat', err)
      }
  },
}
