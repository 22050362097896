export default async function ({ store, redirect }) {
  const loggedIn = await store.state.auth.loggedIn
  const cartRedirBack = await store.state.cart.cartRedirBack
  const cartId = await store.state.cart.cartId

  if (loggedIn) {
    // already use middleware/hasAccount
    // await store.dispatch('shopper/get')
    // const shopper = await store.state.shopper
    // console.log('%c-> middleware.cart shopper ', 'color:#FB923C;', shopper)
    // const hasAccount = await shopper.hasAccount
    // console.log(
    //   '%c-> middleware.cart hasAccount ',
    //   'color:#FB923C;',
    //   hasAccount
    // )
    // if (!hasAccount) {
    //   return redirect('/profile')
    // }
    // # No need redirect if user !hasAddress & hasPayment
    // if (loggedIn && !hasPayment) {
    //   return redirect('/payment/change')
    // }
    // # No need redirect if user !hasAddress & hasPayment
  }

  if (cartRedirBack.length && cartId.length === 0) {
    return redirect(cartRedirBack)
  }
}
