
import { mapState } from 'vuex'
export default {
  name: 'ShopperLayout',
  id: 'shopper',
  head() {
    return {
      bodyAttrs: {
        class: this.$device.isDesktop ? 'desktop' : 'mobile',
      },
    }
  },
  computed: {
    ...mapState({
      loggedIn: (state) => state.auth.loggedIn,
    }),
  },
  watch: {
    loggedIn() {
      if (!this.loggedIn) this.$router.push('/')
    },
  },
  created() {
    console.log('this is: ', this)
    // console.log('process.server', process.server)
    // console.log('process.client', process.client)
    // console.log('isDev: ', this.$nuxt.context.isDev)
    // console.log('APP_DEBUG: ', this.$config.APP_DEBUG)
    // console.log('isDesktop', this.$device.isDesktop)
  },
  mounted() {
    this.$openReplayTrack()
    // GA tracking
    this.$trackPageView()
    // End GA tracking
  },
}
