const initState = () => {
  return {
    card: {},
    cards: [],
    isLoading: false,
    ovoLoading: false,
    shopeePayLoading: false,
    danaLoading: false,
  }
}

export const state = () => initState()

export const mutations = {
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
  reset(state) {
    Object.assign(state, initState())
  },
  choose(state, par) {
    console.log('par:', par)
    // const itemIdx = state.scourier.findIndex((x) => x.product_id === par.product_id)
    // state.scourier.splice(itemIdx, 1)
    state.scourier.push(par)
  },
  add(state, par) {
    state.cards.push(par)
  },
  remove(state, par) {
    const itemIdx = state.cards.findIndex((x) => x.id === par.id)
    state.cards.splice(itemIdx, 1)
  },
}

export const actions = {
  add(ctx, par) {
    ctx.commit('remove', par)
    ctx.commit('add', par)
  },
  sendCard(params) {
    const credit_card_tokens = 'https://api.xendit.co/v2/credit_card_tokens'
    this.$axios
      .$post(credit_card_tokens, params)
      .then((result) => {
        console.log('Xendit sendCard result:', result)
      })
      .catch((err) => {
        console.log('Xendit sendCard err:', err)
      })
  },
}
