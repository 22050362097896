import { render, staticRenderFns } from "./TooltipVersion.vue?vue&type=template&id=273f2bd2&scoped=true&"
import script from "./TooltipVersion.vue?vue&type=script&lang=js&"
export * from "./TooltipVersion.vue?vue&type=script&lang=js&"
import style0 from "./TooltipVersion.vue?vue&type=style&index=0&id=273f2bd2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "273f2bd2",
  null
  
)

export default component.exports