const initState = () => {
  return {
    hasAccount: false,
    email: '',
    first_name: '',
    last_name: '',
    status: 'SHOPPER_STATUS_ACTIVE',
  }
}

export const state = () => initState()

export const mutations = {
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
  reset(state) {
    Object.assign(state, initState())
  },
}

export const actions = {
  async update(ctx, pars) {
    await ctx.commit(
      'set',
      {
        btnAccLoading: true,
      },
      { root: true }
    )
    console.log('update shopper:', pars)
    const SHOPPERSVC = this.$config.SHOPPERSVC
    await this.$axios
      .$put(`${SHOPPERSVC}/v1/shopper`, pars)
      .then((res) => {
        console.log('update shopper response', res)
        ctx.commit('set', pars)
        ctx.commit('set', { hasAccount: true })
        // this.$toast.info('Shopper account updated')
      })
      .catch((err) => {
        console.log('error', err)
      })
    await ctx.commit(
      'set',
      {
        btnAccLoading: false,
      },
      { root: true }
    )
  },
}
