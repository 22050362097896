export const state = () => ({
  counter: 0,
  verifyLoading: false,
  btnAccLoading: false,
  btnAddrLoading: false,
  btnPhoneLoading: false,
  logoutLoading: false,
  confirmPayLoading: false,
  showXenditModal: false,
  xenditLoaded: false,
  btnSaveCardLoading: false,
  modalBottom: false,
  appDomain: '',
  logoMerchant: '',
  latestLoading: false,
  applyVoucherLoading: false,
  payOfferLoading: true,
  modalPotentialReward: false,
  modalPotentialHiw: false,
  showModalPickup: false,
  confirmContinueTrx: false,
  msgContinueTrx: '',
  shipType: 'delivery',
  offerConfirm: 'declined',
  showModalPostalCode: false,
})

export const mutations = {
  increment(state) {
    state.counter++
  },
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
  showHideModalBottom(state) {
    state.modalBottom = !state.modalBottom
  },
}

export const actions = {
  logout(ctx, redirect = true) {
    // console.log('ctx:', ctx)
    // console.log('this:', this)
    ctx.commit('set', { logoutLoading: true })

    this.$auth.logout().then(async () => {
      ctx.dispatch('otp/clear')
      ctx.commit('account/reset')
      ctx.commit('shopper/reset')
      ctx.commit('address/reset')
      ctx.commit('shipping/reset')
      ctx.commit('payment/reset')
      ctx.commit('cart/reset')
      ctx.commit('campaign/reset')
      ctx.commit('promotion/reset')
      ctx.commit('voucher/reset')
      ctx.commit('reward/reset')
      ctx.commit('voucher/resetShopifyPromo')
      ctx.commit('set', { logoutLoading: false })
      this.$cookie.set('payments', false)
      this.$cookie.set('couriers', false)
      this.$cookie.set('get_balance', false)
      if (this.$isCustomDomain())
        this.$toast.info('Logout success', { duration: 3000 })
      const key = this.$config.APP_DEBUG === 'on' ? 'flkd' : 'flkp'
      localStorage.removeItem(key)
      if (redirect) await this.$router.push('/')
    })
  },
  async clearauth(ctx) {
    await ctx.dispatch('otp/clear')
    await this.$auth.logout()
    await this.$router.push('/')
  },
}
