const initState = () => {
  return {
    promo: {},
    product: {},
    default_warehouse: {},
    declineItems: [],
    hasAddedPromo: false,
    totalDiscount: 0,
  }
}
// initial state
export const state = () => initState()

// mutations defined as object
export const mutations = {
  reset(state) {
    Object.assign(state, initState())
  },
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
}

// actions
export const actions = {
  // Reset user states
  reset({ commit }) {
    commit('reset')
  },
  async validatePPO(ctx, payload) {
    // await ctx.commit('setLoading', true)
    const PROMOSVC = this.$config.PROMOSVC
    await this.$axios
      .$post(`${PROMOSVC}/v1/ppos/offer`, { items: payload })
      .then((res) => {
        if (res.data) {
          ctx.commit('set', {
            promo: res.data.promo,
            product: {
              ...res.data.product,
              is_ppo: true,
              default_warehouse: res.data.default_warehouse,
            },
            default_warehouse: res.data.default_warehouse,
          })
        }
      })
      .catch((err) => {
        console.error('validatePPO error', err)
      })
  },
  async declinedPPO(ctx, payload) {
    // await ctx.commit('setLoading', true)
    const PROMOSVC = this.$config.PROMOSVC
    await this.$axios
      .$post(`${PROMOSVC}/v1/ppos/decline`, payload)
      .catch((err) => {
        console.error('declided PPO error', err)
      })
  },
}
