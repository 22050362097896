
export default {
  props: {
    iwidth: {
      type: String,
      default: '14px',
    },
    iheight: {
      type: String,
      default: '14px',
    },
    icolor: {
      type: String,
      default: 'black',
    },
    iclass: {
      type: String,
      default: '',
    },
  },
}
