const initState = () => {
  return {
    products: [],
  }
}
// initial state
export const state = () => initState()

// mutations defined as object
export const mutations = {
  reset(state) {
    Object.assign(state, initState())
  },
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
}

// actions
export const actions = {
  // Reset user states
  reset({ commit }) {
    commit('reset')
  },
  set({ commit }) {
    commit('set')
  },
  getPromoData(ctx, promoKey) {
    const PRODUCTSVC = this.$config.PRODUCTSVC
    // this.$axios.setHeader('X-MID', mid)
    return (
      this.$axios
        // .$get(
        //   `${PRODUCTSVC}/v1/promo/?key${promoKey}`
        // )
        .$get(`${PRODUCTSVC}/v1/promo?key=${promoKey}`)
        .then((result) => {
          console.log('result:', result)
          if (result?.data) {
            ctx.commit('set', { products: result.data })
          }
        })
        .catch((err) => {
          return err
          // if (err.response.status === 404) {
          //   console.log("err", this.$nuxt)
          //   return this.$nuxt.error({ statusCode: 404, message: 'Promo not found' })
          //   // return error({ statusCode: 404, message: 'Promo not found' })
          // }
        })
    )
  },
}
