import slugify from 'slugify'
import { v4 as uid } from 'uuid'

const initState = () => {
  return {
    validateLoading: false,
    validate: {
      valid: false,
      tracking_id: '',
      order: {
        amount: 0,
        discount_amount: 0,
        total_discount_amount: 0,
        total_amount: 0,
        applied_discount_amount: 0,
        total_applied_discount_amount: 0,
        customer_id: null,
        referrer_id: null,
      },
    },
    validations: {
      redeemables: [],
      valid: false,
    },
    listMerchant: [],
    listFlik: [],
    total: 0,
    totalDiscount: 0,
    selVoucher: [],
    promoId: '',
    promoLabel: '',
    promoCode: '',
    promoMid: '',
    promoLevel: '',
    voucherLoading: true,
    vouchers: [],
    selectedVoucher: [],
    flikvouchers: [],
    selectedflikvoucher: [],
    toRemoveVoucher: [],
    toggled: false,
    toggledFlik: false,
  }
}
// initial state
export const state = () => initState()

// mutations defined as object
export const mutations = {
  reset(state) {
    Object.assign(state, initState())
  },
  set(state, params) {
    const keys = Object.keys(params)
    keys.forEach((key) => (state[key] = params[key]))
  },
  resetValidate(state) {
    state.validateLoading = false
    state.validate = initState().validate
  },
  addSelVoucher(state, par) {
    // temporary only 1 voucher allowed to be selected & applied
    state.selVoucher = []
    state.selVoucher.push(par)
  },
  removeSelVoucher(state, par) {
    if (state.selVoucher.length === 1) state.selVoucher = []
    else state.selVoucher = state.selVoucher.filter((v) => v.id !== par.id)
  },
  groupByCategory(state, { key, list }) {
    const groupedList = list.reduce((group, source) => {
      const { category } = source
      group[category] = group[category] ?? []
      group[category].push(source)
      return group
    }, {})
    state[key] = groupedList
  },
  clearSelected(state) {
    state.selVoucher = []
    state.validate = initState().validate
    state.validations = initState().validations
  },
  clearList(state) {
    state.list = []
    state.listFlik = []
    state.listMerchant = []
    state.total = 0
  },
  setShopifyPromo(state, par) {
    state.promoCode = par.promoCode
    state.promoId = par.promoId
    state.promoLabel = par.promoLabel
    state.promoMid = par.promoMid
    state.promoLevel = par.promoLevel
    state.totalDiscount = par.totalDiscount
  },
  resetShopifyPromo(state) {
    state.promoCode = ''
    state.promoId = ''
    state.promoLabel = ''
    state.promoMid = ''
    state.promoLevel = ''
    state.totalDiscount = 0
  },
  addVoucher(state, vch) {
    state.selectedVoucher.push(vch)
  },
  removeVoucher(state, vch) {
    state.selectedVoucher = state.selectedVoucher.filter(
      (v) => v.code !== vch.code
    )
  },
  resetVouchers(state) {
    state.vouchers = []
    state.selectedVoucher = []
    state.selectedflikvoucher = []
  },
  addFlikVoucher(state, vch) {
    state.selectedflikvoucher.push(vch)
  },
  removeFlikVoucher(state, vch) {
    state.selectedflikvoucher = state.selectedflikvoucher.filter(
      (v) => v.code !== vch.code
    )
  },
}

// actions
export const actions = {
  // Reset user states
  reset({ commit }) {
    commit('reset')
  },
  set({ commit }) {
    commit('set')
  },
  async clearSelected({ commit }) {
    await commit('clearSelected')
  },
  // TODO voucherify voucher not implemented
  async getList(ctx) {
    // get cookie
    const voucher_list = this.$cookie.get('voucher_list')
    // if cookie valid
    if (voucher_list) {
      return true
    } else {
      const query = this.$serialize({
        '[filters][junction]': 'and',
        '[filters][metadata.v_source][conditions][$in][0]': 'Flik',
        '[filters][metadata.v_source][conditions][$in][1]': 'Merchant',
        '[filters][active][conditions][$enabled][0]': true,
        '[filters][expiration_date][conditions][$after][0]':
          this.$dayjs().toISOString(),
        '[limit]': 40,
        '[page]': 1,
        '[order]': '-expiration_date',
      })

      // const signature = HmacSHA256(uid(), this.$config.XSJSX).toString()
      // this.$axios.setHeader('X-Signature', signature)

      this.$axios.setHeader('X-Signature', uid(32))

      return await this.$axios
        .$get(`/vouchers?${query}`)
        .then((res) => {
          if (res?.data?.vouchers.length > 0) {
            this.$cookie.set('voucher_list', true, {
              path: '/',
              maxAge: parseInt(this.$config.VOUCHERIFY_CACHE_AGE),
              // maxAge: 3600 * 24 // 24 hours lifetime
            })

            const list = []
            for (const v of res.data.vouchers) {
              list.push({
                id: v.id,
                code: v.code,
                info: v.additional_info,
                expiration_date: v.expiration_date || '',
                start_date: v.start_date || '',
                source: v.metadata?.v_source || 'Flik',
                category: v.metadata?.v_category || 'Shopping',
                icon: v.metadata?.v_icon || 'flik',
                minimum_order: v.metadata?.minimum_order || '',
                max_discount:
                  v.discount.amount_limit / 100 ||
                  v.discount.amount_off / 100 ||
                  0,
                shipping_limit_info: v.metadata?.shipping_limit_info || '',
                payment_limit_info: v.metadata?.payment_limit_info || '',
              })
            }

            const listFlik = list.filter((v) => v.source === 'Flik')
            const listMerchant = list.filter((v) => v.source === 'Merchant')
            ctx.commit('set', { listFlik, listMerchant, total: list.length })
            ctx.commit('groupByCategory', { key: 'listFlik', list: listFlik })
            ctx.commit('groupByCategory', {
              key: 'listMerchant',
              list: listMerchant,
            })
          }
        })
        .catch((err) => {
          console.error('voucher/getList err:', err)
        })
    }
  },
  async validate(ctx, { vcode, param }) {
    // const signature = HmacSHA256(uid(), this.$config.XSJSX).toString()
    // this.$axios.setHeader('X-Signature', signature)

    this.$axios.setHeader('X-Signature', uid(32))

    return await this.$axios
      .$post(`/vouchers/${vcode}/validate`, param)
      .catch((err) => {
        console.error('err:', err)
        ctx.commit('set', { validateLoading: false })
      })
  },
  async validations({ commit, state }, { param }) {
    console.group('voucher/validations')
    commit('set', { validateLoading: true })
    // const signature = HmacSHA256(uid(), this.$config.XSJSX).toString()
    // this.$axios.setHeader('X-Signature', signature)
    this.$axios.setHeader('X-Signature', uid(32))

    return await this.$axios
      .$post(`/validations`, param)
      .then(async (res) => {
        console.log('res:', res.data)
        const data = res?.data
        commit('set', { validations: data })
        if (!data.valid) {
          console.error('voucher validations invalid')
          const inapplicable = data.redeemables.find(
            (vc) => vc.status === 'INAPPLICABLE'
          )
          commit('set', { validateLoading: false })
          commit('removeSelVoucher', inapplicable)
        } else {
          this.$toast.clear()
          const totalDisc =
            (await data.order.total_applied_discount_amount) / 100
          const totalOrder = (await data.order.total_amount) / 100
          console.error('totalDisc:', totalDisc)
          commit('set', {
            totalDiscount: Math.round(totalDisc),
            validateLoading: false,
          })
          commit('cart/setTotalDiscount', Math.round(totalDisc), { root: true })
          commit('cart/setTotalOrder', Math.round(totalOrder), { root: true })
        }
        console.groupEnd()
      })
      .catch((err) => {
        console.error('err:', err)
        const duration = 7000
        const rescode = err?.response?.data?.code
        if (rescode === 401) {
          this.$toast.error('Unauthorized', { duration })
        }
        if (rescode === 400) {
          console.log('err?.response?.data:', err?.response?.data)
          this.$toast.error('Request invalid', { duration })
        }
        if (rescode === 429) {
          console.log('err?.response?.data:', err?.response?.data)
          this.$toast.error('Voucher request limit', { duration })
        }

        commit('set', { validateLoading: false })
        console.groupEnd()
      })
  },
  async applyVoucher({ state, rootState, dispatch, commit }, { redir }) {
    console.group('applyVoucher')
    const totalOrder = rootState.cart.totalAmount
    const cartRawItems = rootState.cart.raw
    console.log('totalOrder:', totalOrder)
    if (cartRawItems.length < 1) {
      this.$toast.info('To apply voucher add product to cart first', {
        duration: 5000,
      })
      if (redir !== '') {
        commit('clearSelected')
        this.$router.push(redir)
      }
      return false
    }
    const shopperAcc = rootState.shopper.account
    const defPayment = rootState.shopper.defPayment
    const scourier = rootState.shipping.scourier
    const cartCheckedItems = rootState.cart.checkedItems
    const redeemables = await state.selVoucher.map((sv) => {
      return {
        object: 'voucher',
        id: sv.code,
      }
    })

    const param = {
      customer: {
        source_id: shopperAcc.phone,
      },
      redeemables,
      order: {
        amount: totalOrder * 100,
        metadata: {},
      },
    }

    const payment_id = defPayment?.type || null
    console.log('payment_id:', payment_id)
    if (payment_id !== null) {
      Object.assign(param.order.metadata, { payment_id })
    }

    const couriers = []
    for (const sc of scourier) {
      couriers.push(sc.id)
    }
    console.log('couriers:', couriers)

    const merchants = []
    const slugOpt = {
      replacement: this.$config.SLUGIFY_REPLACEMENT, // replace spaces with replacement character, defaults to `-`
      lower: this.$config.SLUGIFY_LOWER, // convert to lower case, defaults to `false`
      locale: this.$config.SLUGIFY_LOCALE, // language code of the locale to use
      trim: this.$config.SLUGIFY_TRIM, // trim leading and trailing replacement chars, defaults to `true`
    }
    for (const ci of cartCheckedItems) {
      const merchant_name = slugify(ci.merchant_name, slugOpt)
      merchants.push(merchant_name)
    }

    if (couriers.length > 0) {
      Object.assign(param.order.metadata, { courier_id: couriers })
    }

    if (merchants.length > 0) {
      Object.assign(param.order.metadata, { merchants })
    }

    console.log('param:', param)
    console.groupEnd()
    return await dispatch('validations', { param })
  },
  async validation(ctx) {
    // dummy validation
    const totalDisc = ctx.state.promoCode === 'SH0P1FY' ? 20000 : 10000
    ctx.commit('set', { totalDiscount: totalDisc })
    const totalOrder = (await ctx.rootState.cart.totalAmount) - totalDisc

    ctx.commit('cart/setTotalDiscount', Math.round(parseInt(totalDisc)), {
      root: true,
    })
    ctx.commit('cart/setTotalOrder', Math.round(parseInt(totalOrder)), {
      root: true,
    })
  },
  // TODO voucherify voucher not implemented
  async codeValidation({ state }, params) {
    console.log('state.promoCode : ', state.promoCode)
    const PROMOSVC = this.$config.PROMOSVC

    if (state.promoCode.length < 1) return

    return await this.$axios.$post(
      `${PROMOSVC}/v1/code/${state.promoCode}`,
      params
    )
  },
  async applyVoucherCode(ctx) {
    const storeAppId = await ctx.rootState.cart.storeAppId
    ctx.commit('set', { confirmPayLoading: true }, { root: true })
    if (storeAppId.length > 0) {
      // set loading button Pay
      const shopperAcc = await ctx.rootState.shopper.account
      const checkedItems = await ctx.rootState.cart.checkedItems
      const validationParams = {
        customer: {
          email: shopperAcc.email,
        },
        order: {
          items: checkedItems.map((ci) => {
            return {
              extid: ci.merchant_item_id,
              qty: ci.qty,
            }
          }),
        },
        metadata: {
          platform: 'shopify',
          store_app_id: storeAppId,
        },
      }

      await ctx
        .dispatch('codeValidation', validationParams)
        .then(async (res) => {
          // console.log('applyVoucherCode response', res)
          if (res.data?.applied) {
            const promoApplied = res.data?.applied
            const fApplied = promoApplied[0]
            console.log('fApplied:', fApplied)
            const discount = parseInt(fApplied?.applied_amount || 0)
            let totalOrder = parseInt(fApplied?.discount_amount || 0)
            const totalShipping = await ctx.rootState.shipping.totalCost
            console.log('totalShipping applyVoucherCode:', totalShipping)
            console.log('totalOrder BEFORE applyVoucherCode:', totalOrder)
            if (totalShipping) {
              totalOrder += totalShipping
            }
            console.log('totalOrder AFTER applyVoucherCode:', totalOrder)
            await ctx.commit('setShopifyPromo', {
              totalDiscount: discount,
              promoId: fApplied?.promo_id || '',
              promoLabel: fApplied?.discount_label || '',
              promoCode: fApplied?.promo_code || '',
              promoLevel: fApplied?.applied_on || '',
              promoMid: fApplied?.mid || '',
            })
            await ctx.commit('cart/setTotalDiscount', discount, {
              root: true,
            })
            await ctx.commit('cart/setTotalOrder', totalOrder, {
              root: true,
            })

            await this.$checkPromotion()

            await ctx.commit(
              'set',
              { confirmPayLoading: false },
              { root: true }
            )
          } else {
            ctx.commit('resetShopifyPromo')
            ctx.commit('set', { confirmPayLoading: false }, { root: true })
            console.error('applyVoucherCode data null')
          }
        })
        .catch((err) => {
          console.log('err:', err)
          ctx.commit('resetShopifyPromo')
          ctx.commit('set', { confirmPayLoading: false }, { root: true })
          console.error('applyVoucherCode error', err)
          const errors = err?.response?.data?.errors || []
          const errorLength = err?.response?.data?.errors.length || 0
          for (let idx = 0; idx < errorLength; idx++) {
            this.$toast.error(errors[idx], { duration: 7000 })
          }
        })

      ctx.commit(
        'set',
        { applyVoucherLoading: false, confirmPayLoading: false },
        { root: true }
      )
    } else {
      console.log('no voucher code applied')
      ctx.commit('resetShopifyPromo')
      ctx.commit(
        'set',
        { applyVoucherLoading: false, confirmPayLoading: false },
        { root: true }
      )
    }
  },
  async toggleVoucher({ commit, state }, voucher) {
    const inSelectedVoucher = state.selectedVoucher.some(
      (x) => x.code === voucher.code
    )
    console.log('voucher:', voucher)
    console.log('inSelectedVoucher:', inSelectedVoucher)

    await commit('set', { toggled: true })

    if (!inSelectedVoucher) {
      await commit('addVoucher', voucher)
    } else {
      await commit('removeVoucher', voucher)
    }
    // only allow 1 voucher selected
    const remVoucher = state.selectedVoucher.filter(
      (v) => v.code !== voucher.code
    )
    if (remVoucher.length) {
      for (const vch of remVoucher) {
        await commit('removeVoucher', vch)
      }
    }
    // only allow 1 voucher selected
  },
  async toggleFlikVoucher({ commit, state }, voucher) {
    const inselectedflikvoucher = state.selectedflikvoucher.some(
      (x) => x.code === voucher.code
    )

    console.log('flik voucher:', voucher)
    console.log('inselectedflikvoucher:', inselectedflikvoucher)

    await commit('set', { toggledFlik: true })

    if (!inselectedflikvoucher) {
      await commit('addFlikVoucher', voucher)
    } else {
      await commit('removeFlikVoucher', voucher)
    }
    // only allow 1 voucher selected
    const remVoucher = state.selectedflikvoucher.filter(
      (v) => v.code !== voucher.code
    )
    if (remVoucher.length) {
      for (const vch of remVoucher) {
        await commit('removeFlikVoucher', vch)
      }
    }
    // only allow 1 voucher selected
  },
  async getVouchers({ commit, rootState, dispatch }, { id }) {
    await commit('set', { voucherLoading: true })
    await commit('set', { applyVoucherLoading: true }, { root: true })

    const PROMOSVC = this.$config.PROMOSVC

    let platformType = 'web'

    const cookieTrxFrom = this.$cookie.get('transaction_from') // format ==> platform-amplitudeSessionID
    if (cookieTrxFrom) {
      const val = String(cookieTrxFrom).split('-')

      if (val.length === 2) {
        platformType = val[0]
      }
    }

    await this.$axios
      .$get(`${PROMOSVC}/v1/discount/cart/${id}`, {
        headers: {
          'X-Client-Type': platformType,
        },
      })
      .then(async (res) => {
        commit('resetVouchers')
        const resvouchers = await res.data
        // get only applicable vouchers
        // and voucher not in removableDiscounts
        // const removableDiscounts = await rootState.cart.removableDiscounts
        const vouchers = []
        for (const rvoucher of resvouchers?.merchant_vouchers || []) {
          // const isInRemDisc = await removableDiscounts.some(
          //   (rd) => rd.code === rvoucher.code
          // )

          vouchers.push(rvoucher)

          // mark checked is_applied
          if (rvoucher.is_applied && rvoucher.is_applicable) {
            await commit('addVoucher', rvoucher)
          }
        }
        console.log('vouchers:', vouchers)

        const flikvouchers = []
        for (const rvoucher of resvouchers?.flik_vouchers || []) {
          flikvouchers.push(rvoucher)

          // mark checked is_applied
          if (rvoucher.is_applied && rvoucher.is_applicable) {
            await commit('addFlikVoucher', rvoucher)
          }
        }
        console.log('all flik vouchers:', flikvouchers)

        if (vouchers.length) {
          await commit('set', { vouchers })
        }
        if (flikvouchers.length) {
          await commit('set', { flikvouchers })
        }
        await commit('set', { voucherLoading: false })
        await commit('set', { applyVoucherLoading: false }, { root: true })
      })
      .catch((err) => {
        console.error('getVouchers err:', err)
        commit('set', { voucherLoading: false })
        commit('set', { applyVoucherLoading: false }, { root: true })
      })
  },
}
