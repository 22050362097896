import { render, staticRenderFns } from "./logocenter.vue?vue&type=template&id=f7b78a00&"
import script from "./logocenter.vue?vue&type=script&lang=js&"
export * from "./logocenter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PoweredFlik: require('/runner/_work/checkout/checkout/components/PoweredFlik.vue').default})
